
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import StringSelectBox, { StringSelectBoxOptions } from '@/cloudfun/components/SelectStringBox.vue'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ProductFileUpload from "@/components/file/ProductFileUpload.vue";
import { formatAmount } from '@/libs/utils'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    StringSelectBox,
    ProductFileUpload,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("ProductCreate");
    const canUpdate = checkPermissions("ProductUpdate");
    const canDelete = checkPermissions("ProductDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '應收帳款管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      // canCreate: canCreate,
      // canUpdate: canUpdate,
      // canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '應收帳款管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '應收帳款管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        // {
        //   field: 'Name',
        //   title: '名稱',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        // },
        // {
        //   field: 'Number',
        //   title: '帳款編號',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        // },
        {
          field: 'Customer.Name',
          title: '客戶',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'InvoiceNumber',
          title: '銷貨單號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ShippingDate',
          title: '出貨日期',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'ShippingAmount',
          title: '出貨單金額',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'UncollectedAmount',
          title: '未收金額',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ExpectedAccountingDate',
          title: '預計入帳日',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        { 
          field: 'Status',
          title: '狀態', 
          showHeaderOverflow: true, 
          showOverflow: true, 
          sortable: true, 
          formatter: ({ cellValue }) => model ? Object.values(model.enums.AccountsReceivableStatus).find(e => e.Value === cellValue)?.Name : undefined 
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('accountsReceivable/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('accountsReceivable/query') : undefined,
        save: model ? (params) => model.dispatch('accountsReceivable/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'CustomerId',
          title: '客戶名稱',
          span: 12,
          slots: { default: "column-CustomerId" }
        },
        {
          field: 'InvoiceNumber',
          title: '銷貨單號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Name',
        //   title: '名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'ShippingDate',
          title: '出貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ExpectedAccountingDate',
          title: '預計入帳日',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'CurrencyType',
          title: '幣別',
          span: 12,
          itemRender: { name: '$select', props: { type: 'number' }, options: [
                    { value: 0, label: "台幣" },
                    { value: 1, label: "美金" },
                    { value: 2, label: "日幣" },
                    { value: 3, label: "人民幣" },
                    { value: 4, label: "歐元" }
                ]}
        },
        {
          field: 'ShippingAmount',
          title: '出貨單金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'Tax',
          title: '稅額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'TotalAmount',
          title: '總金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },     
        {
          field: 'UncollectedAmount',
          title: '未收金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'RemittanceAmount',
          title: '已收金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'Allowance',
          title: '折讓金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },            
        {
          field: 'RemittanceDate',
          title: '已收款日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'JobTickeNumber',
          title: '工單號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'EmployeeId',
          title: '業務',
          span: 12,
          slots: { default: "column-employeeId" }
        },
        {
          field: 'SPIMAX',
          title: 'SP/I-MAX',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },   
        {
          field: 'Number',
          title: '帳款編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Status',
          title: '簽核狀態',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.AccountsReceivableStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        // {
        //   field: 'RemittanceExchangeRate',
        //   title: '匯款匯率',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        // },
        // {
        //   field: 'OrderExchangeRate',
        //   title: '匯款匯率',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        // },
        // {
        //   field: 'RedemptionNumber',
        //   title: '沖款單號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'IncomeDate',
        //   title: '新加坡進款日',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        // },
        // {
        //   field: 'SGRemittanceAmount',
        //   title: '匯款金額(新加坡)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        // },
        // {
        //   field: 'SGRemark',
        //   title: '備註(新加坡)',
        //   span: 24,
        //   itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        // },
      ],
      rules: {
        //CustomerId: [{ required: true }],
        Name: [{ type: 'string', required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      row.SGRemittanceAmount = 0;
      row.Status = 0;
      row.OrderExchangeRate = 0;
      row.RemittanceAmount = 0;
      row.RemittanceExchangeRate = 0;
      row.UncollectedAmount = 0;
      row.ShippingAmount = 0;
      callback();
    }

    const unique = (data: any, name: string) => {
      let result = [
        ...new Set(data.map((e: any) => e[name])),
      ];
      return result;
    };

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
    })

    const employeeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
      linkVXEModal,
      linkModal,
      formatAmount,
      employeeSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
})
