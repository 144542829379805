
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    DepartmentId: {
      type: String,
      default: ""
    },
    aid: Number,
    contentType: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const jobId = ref(0);
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
        if (table.formData.CompletionDate == '0001-01-01T00:00:00') {
          table.formData.CompletionDate = new Date();
        }
      },
      delete(row: any) {
        grid.value.remove(row);
        emit('updateTotal');
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.formData.Quantity = 1;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        if (table.isEditing) {
          // 編輯確認
          if(table.formData.Purpose != null && table.formData.ProductSize == null && table.formData.Exterior == null && table.formData.Performance == null){
            alert('尺寸、外觀、功能至少要輸入一項');
            return false;
          }
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          if(table.formData.Purpose != null && table.formData.ProductSize == null && table.formData.Exterior == null && table.formData.Performance == null){
            alert('尺寸、外觀、功能至少要輸入一項');
            return false;
          }
          //console.log('table.selectedR', table.selectedRow);
          // console.log('submit table', table);
          table.formData.ItemNo = getNextOrdinal();
          await grid.value.insert(table.formData);
        }
        emit('updateTotal');
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
        AcquireDate: [{ required: true }],
        SendDate: [{ required: true }],
        Purpose:[{ required: true }],
        // ProductSize:[{ required: true }],
        // Exterior:[{ required: true }],
        // Performance:[{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        console.log(props.contentType)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorProductOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductSize",
          title: "規格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          return model!.dispatch("product/query", params)
        }
      }
    };

    const customerProduct = async (data: any) => {
      if (data?.ProductId) {
        // console.log(data.ProductId);
        try {
          const temp = await model?.dispatch("product/find", data.ProductId);
          if (temp) {
            // console.log(temp);
            data.Name = temp.ProductName;
            data.Specification = temp.ProductSize;
            data.Color = temp.Color;
            data.PaperTube = temp.PaperTube;
            data.TitlePaper = temp.TitlePaper;
            data.Package = temp.Package;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };
    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };
    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const viscosityType = [
      { label: "水膠", value: "水膠" },
      { label: "熱熔膠", value: "熱熔膠" },
      { label: "油膠", value: "油膠" },
      { label: "壓克力膠", value: "壓克力膠" },
      { label: "橡膠", value: "橡膠" },
    ]

    const jobTickeProduct = async (data: any) => {
      if (data?.WorkOrder) {
        try {
          data.jobticketId = data.WorkOrder;
          jobId.value = data.WorkOrder;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectJobTickeItem = async (data: any) => {
      if (data?.WorkOrder) {
        try {
          data.jobticketId = data.WorkOrder;
          jobId.value = data.WorkOrder;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    const getNextOrdinal = () => {
      const data = grid.value.getTableData().fullData;
      if (!data || data.length == 0) return 1;
      var maxOrdinal = Math.max(...data.map((e: any) => e.ItemNo));
      return (maxOrdinal ?? 0) + 1;
    }

    const purposeContent = [
      { label: "大貨樣品", value: "大貨樣品" },
      { label: "客戶需求樣品", value: "客戶需求樣品" },
      { label: "SGS檢驗樣品", value: "SGS檢驗樣品" },
      { label: "客戶檢驗樣品", value: "客戶檢驗樣品" },
      { label: "客戶展示品", value: "客戶展示品" },
    ]

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
      formatDate,
      customerProduct,
      selectorProductOptions,
      jobTickeSelectorOptions,
      jobTickeProduct,
      selectJobTickeItem,
      viscosityType,
      purposeContent
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
