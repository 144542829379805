<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">文件簽核</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedType"
              @change="grid.refresh"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇表單類型</option>
              <option
                v-for="(item, index) in Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } })"
                :key="'type' + index"
                :label="item.label"
                :value="item.value"
                class="py-3"
              ></option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initAddData"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
          @saveSuccess="onSaveSuccess"
        >
          <template #flow="{ row }">
            <vxe-button
              type="button"
              content="查看"
              @click="flowModal.show(row)"
            ></vxe-button>
          </template>
          <template #sign="{ row }">
            <vxe-button
              type="button"
              content="查看"
              @click="signModal.show(row)"
            ></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-file="{ data }">
                <DocumentFileUpload
                  v-if="data.Id"
                  ref="fileGrid"
                  :title="''"
                  v-bind="fileModal.uploadOptions"
                  :canUpload="filereadType"
                  :canDelete="filereadType"
                />
              </template>
              <template #column-submitter="{ data }">
                <select-box
                  v-bind="userSelectorOptions"
                  :disabled="true"
                  :readonly="true"
                  v-model="data.SubmitterId"
                />
              </template>
              <template #column-department="{ data }">
                <select-box
                  v-bind="departmentSelectorOptions"
                  :disabled="true"
                  :readonly="true"
                  v-model="data.XmlData.DepartmentId"
                />
              </template>
              <template #column-agent-department="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="departmentSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.AgentDepartmentId"
                />
              </template>
              <template #column-items="{ data }">
                <InvoiceQuarterlyItemTable
                  v-if="data.XmlData.InvoiceQuarterlyItems"
                  ref="itemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.InvoiceQuarterlyItems"
                  :readType="true"
                />
              </template>
              <template #column-types="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="typesSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.Types"
                />
              </template>
              <template #column-reasons="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="reasonsSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.CauseReason"
                />
              </template>
              <template #column-annex="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="annexSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.Annex"
                />
              </template>
              <!-- BOM表異動申請 1 -->
              <template #column-ProductWrokOrderItems="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="wrokOrderItemsSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ProductWrokOrderItems"
                />
              </template>
              <template #column-FinishedProductWrokOrderItems="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="wrokOrderItemsSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.FinishedProductWrokOrderItems"
                />
              </template>
              <template #column-ReRollWrokOrderItems="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="wrokOrderItemsSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ReRollWrokOrderItems"
                />
              </template>
              <template #column-ProductModifyItems="{ data }">
                <BOMUsageQuantityModifyItemTable
                  v-if="data.XmlData"
                  ref="ProductModifyItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.ProductModifyItems"
                  :readType="true"
                />
              </template>
              <template #column-FinishedProductModifyItems="{ data }">
                <BOMUsageQuantityModifyItemTable
                  v-if="data.XmlData"
                  ref="FinishedProductModifyItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.FinishedProductModifyItems"
                  :readType="true"
                />
              </template>
              <template #column-ReRollModifyItems="{ data }">
                <BOMUsageQuantityModifyItemTable
                  v-if="data.XmlData"
                  ref="ReRollModifyItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.ReRollModifyItems"
                  :readType="true"
                />
              </template>
              <!-- BOM表異動申請 1 -->

              <!-- 取替代料件異動申請表 6 -->
              <template #column-MaterialSubstitutesItems="{ data }">
                <MaterialSubstitutesItemsTable
                  v-if="data.XmlData"
                  ref="MaterialSubstitutesItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 取替代料件異動申請表 6 -->

              <!-- 付款申請 9 -->
              <template #column-ApplicationTypes="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="applicationTypesSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ApplicationTypes"
                />
              </template>
              <!-- 付款申請 9 -->

              <!-- 人員考核月報表 10 -->
              <template #column-AssessmentItems="{ data }">
                <AssessmentItemsTable
                  v-if="data.XmlData"
                  ref="AssessmentItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 人員考核月報表 9 -->

              <!-- 品號異動申請單 11 -->
              <template #column-ProductNumberModifuItems="{ data }">
                <ProductNumberModifuItemsTable
                  v-if="data.XmlData"
                  ref="ProductNumberModifuItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 品號異動申請單 11 -->

              <!-- 請購單 報價單 12 13 -->
              <template #column-PurchaseRequisitionType="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="purchaseRequisitionTypeSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.Types"
                />
              </template>
              <template #column-PurchaseRequisitionItems="{ data }">
                <PurchaseRequisitionItemsTable
                  v-if="data.XmlData"
                  ref="PurchaseRequisitionItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 請購單 報價單 12 13 -->

              <!-- 客戶訂單 14 -->
              <template #column-OrderItems="{ data }">
                <OrderItemsTable
                  v-if="data.XmlData"
                  ref="OrderItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 客戶訂單 14 -->

              <!-- 客戶超限放行單 15 -->
              <template #column-CustomerCreditItems="{ data }">
                <CustomerCreditItemsTable
                  v-if="data.XmlData"
                  ref="CustomerCreditItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 客戶超限放行單 15 -->

              <!-- 巡檢作業 16 -->
              <template #column-CheckRecordItems="{ data }">
                <CheckRecordItemsTable
                  v-if="data.XmlData"
                  ref="CheckRecordItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 巡檢作業 16 -->

              <!-- 來料檢驗 17 -->
              <template #column-IQCItems="{ data }">
                <IQCItemsTable
                  v-if="data.XmlData"
                  ref="IQCItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 來料檢驗 17 -->

              <!-- 生產規格首件QC檢驗紀錄表(分條)  21 -->
              <template #column-TypeAProductionCheckItems="{ data }">
                <TypeAProductionCheckItemsTable
                  v-if="data.XmlData"
                  ref="TypeAProductionCheckItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 生產規格首件QC檢驗紀錄表(分條)  21 -->

              <!-- 生產規格首件QC檢驗紀錄表(分條)  22 45 -->
              <template #column-WinderItems="{ data }">
                <WinderItemsTable
                  v-if="data.XmlData"
                  ref="WinderItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.WinderItems"
                  :readType="true"
                />
              </template>
              <template #column-RollItems="{ data }">
                <RollItemsTable
                  v-if="data.XmlData"
                  ref="RollItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.RollItems"
                  :readType="true"
                />
              </template>
              <!-- 生產規格首件QC檢驗紀錄表(分條)  22 45 -->

              <!-- 生產現場包裝首件QC檢查表 23 -->
              <template #column-ProductionPackageQCItems="{ data }">
                <ProductionPackageQCItemsTable
                  v-if="data.XmlData"
                  ref="ProductionPackageQCItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 生產現場包裝首件QC檢查表 23 -->

              <!-- 產品最終品質檢驗報告 24 -->
              <template #column-FinalQualityInspectionReportItems="{ data }">
                <FinalQualityInspectionReportItemsTable
                  v-if="data.XmlData"
                  ref="FinalQualityInspectionReportItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 產品最終品質檢驗報告 24 -->

              <!-- 樣品製作樣品通知單 25 -->
              <template #column-SalesItems="{ data }">
                <SalesItemsTable
                  v-if="data.XmlData"
                  ref="SalesItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.SalesItems"
                  :readType="true"
                />
              </template>
              <template #column-StaffItems="{ data }">
                <StaffItemsTable
                  v-if="data.XmlData"
                  ref="StaffItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.StaffItems"
                  :readType="true"
                />
              </template>
              <template #column-TrackingItems="{ data }">
                <TrackingItemsTable
                  v-if="data.XmlData"
                  ref="TrackingItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.TrackingItems"
                  :readType="true"
                />
              </template>
              <!-- 樣品製作樣品通知單 25 -->

              <!-- 產品最終品質檢驗報告 26 -->
              <template #column-HumidityCheckRecordItems="{ data }">
                <HumidityCheckRecordItemsTable
                  v-if="data.XmlData"
                  ref="HumidityCheckRecordItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 產品最終品質檢驗報告 26 -->

              <!-- 工單 30 -->
              <template #column-JobTickeDocumentItems="{ data }">
                <JobTickeDocumentItemsTable
                  v-if="data.XmlData"
                  ref="JobTickeDocumentItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 工單 30 -->

              <!-- 工單 31 -->
              <template #column-JobTicke="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="jobTickeSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.JobTickeId"
                />
              </template>
              <template #column-Customer="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="customerSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.CustomerId"
                />
              </template>
              <!-- 進款單 31 -->
              <template #column-RemittanceSlipItems="{ data }">
                <RemittanceSlipItemsTable
                  v-if="data.XmlData"
                  ref="RemittanceSlipItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <template #column-invoice>
                <div
                  v-if="invoice && invoice.InvoiceNumber"
                  class="m-5"
                >
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th
                          class="border border-gray-400 bg-gray-200 py-2 px-4 text-center"
                          colspan="4"
                        >Invoice No：{{ invoice.InvoiceNumber }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.Customer?.Name }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">Date</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.PIDate ? formatDate(invoice.PIDate,
                          'yyyy/MM/dd') : "" }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">收貨人</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.Consignee }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">託運人</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.Shipper }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">船名航次</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.VesselNameVoyage }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">裝載港口</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.PortofLoading }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">目的地</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.FinalDestination }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETD</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.ETD ? formatDate(invoice.ETD,
                          'yyyy/MM/dd') : "" }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETA</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.ETA ? formatDate(invoice.ETA,
                          'yyyy/MM/dd') : "" }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">原產國</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.Country }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">貿易條件</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.TermDelivery }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">Order Number</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.OrderNumber }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">Container Number</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.ContainerNumber }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">狀態</td>
                        <td class="border border-gray-400 py-2 px-4">{{ Object.values(model.enums.InvoiceStatus).find(e =>
                          e.Value === invoice.Status)?.Name }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">HTS Code</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.HTSCode }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">SQ.M (平方米)</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.SQM }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">ShippingMark</td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          colspan="3"
                        >{{ invoice.ShippingMark }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          colspan="3"
                        >{{ invoice.Remark }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">總棧板數量</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.Pallets }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">總下單件數</td>
                        <td class="border border-gray-400 py-2 px-4">{{ invoice.OrderQuantity }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">總金額</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.TotleAmount, 2) }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">版費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.LicenseFee, 2) }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">檢驗費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.InspectionFee, 2) }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">模具費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.ToolingFee, 2) }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">樣品費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.SampleFee, 2) }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">運費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.ShippingFee, 2) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-5 ml-1 mb-1">項目資料：</div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">工單號碼</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品號</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品名</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">箱數</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">套/箱</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總下單件數</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總卷數</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總棧板數量</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">單位價格</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總金額</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">備註</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in invoice.Items"
                        :key="item.Id"
                      >
                        <td class="border border-gray-400 py-2 px-4">{{ item.JobTickeNo }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductNo }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductName }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Quantity }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Radix }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.OrderQuantity }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.RollQuantity }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Pallets }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(item.UnitPrice) }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(item.TotleAmount, 2) }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Remark }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #column-packinglist>
                <div
                  v-if="packingList && packingList.InvoiceNumber"
                  class="m-5"
                >
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th
                          class="border border-gray-400 bg-gray-200 py-2 px-4 text-center"
                          colspan="4"
                        >PackingList No：{{ packingList.InvoiceNumber }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Customer?.Name }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">Date</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.PackingListDate ?
                          formatDate(packingList.PackingListDate, 'yyyy/MM/dd') : "" }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">收貨人</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Consignee }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">託運人</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Shipper }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">船名航次</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.VesselNameVoyage }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">裝載港口</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.PortofLoading }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">目的地</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.FinalDestination }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETD</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.ETD ? formatDate(packingList.ETD,
                          'yyyy/MM/dd') : "" }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">ETA</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.ETA ? formatDate(packingList.ETA,
                          'yyyy/MM/dd') : "" }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">原產國</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Country }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">貿易條件</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.TermDelivery }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">Seal</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Seal }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">Container Number</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.ContainerNumber }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">狀態</td>
                        <td class="border border-gray-400 py-2 px-4">{{
                          Object.values(model.enums.PackingListStatus).find(e => e.Value === packingList.Status)?.Name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">ShippingMark</td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          colspan="3"
                        >{{ invoice.ShippingMark }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          colspan="3"
                        >{{ invoice.Remark }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">總棧板數量</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Pallets }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">總下單件數</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.OrderQuantity }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">總箱數</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.Quantity }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">CBM</td>
                        <td class="border border-gray-400 py-2 px-4">{{ packingList.CBM }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">版費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.LicenseFee, 2) }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">檢驗費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.InspectionFee, 2) }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">模具費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.ToolingFee, 2) }}</td>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">樣品費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.SampleFee, 2) }}</td>
                      </tr>
                      <tr>
                        <td class="border border-gray-400 bg-gray-200 py-2 px-4">運費</td>
                        <td class="border border-gray-400 py-2 px-4">{{ formatAmount(invoice.ShippingFee, 2) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-5 ml-1 mb-1">項目資料：</div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品號</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">品名</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">箱數</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">套/箱</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總下單件數</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總卷數</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總棧板數量</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@G.W</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">TotleG.W</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">@N.W</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">TotleN.W</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">總重量</th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">CBM (立方米)</th>
                        <!-- <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">顯示厚度</th> -->
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">備註</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in packingList.Items"
                        :key="item.Id"
                      >
                        <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductNo }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Product?.ProductName }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Quantity }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Radix }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.OrderQuantity }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.RollQuantity }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.Pallets }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.GW }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.TotleGW }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.NW }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.TotleNW }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.TotalWeight }}</td>
                        <td class="border border-gray-400 py-2 px-4">{{ item.CBM }}</td>
                        <!-- <td class="border border-gray-400 py-2 px-4">{{ item.ThicknessDisplay == true ? '是' : item.ThicknessDisplay == false ? '否' : '不選擇' }}</td> -->
                        <td class="border border-gray-400 py-2 px-4">{{ item.Remark }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <!-- 工單 31 -->

              <!-- 權限開放申請單 32 33 -->
              <template #column-requestContent="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="requestContentSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.RequestContent"
                />
              </template>
              <!-- 權限開放申請單 32 33 -->

              <!-- 年終績效評估 34 -->
              <template #column-PerformanceEvaluationPartA>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th
                          class="border border-gray-400 bg-gray-200 py-2 px-4 text-center"
                          colspan="5"
                        >整體績效評核標準</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Coach Needed(C) 需輔導
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Improvement Needed(I) 需改進
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Good(G) 良好
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Distinguished(D) 優秀
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Excellent(E) 傑出
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Does not meet requirements of the job. A poor performer who consistently fails to get the job
                          done.<br>
                          無法達成工作目標，在完成工作的過程之中，持續不斷的出錯或無法完成工作。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Meets some but not all requirements of the job. Not entirely performing up to the necessary
                          level. This may be a new job assignment and growth is needed to meet full job.<br>
                          僅達成部分工作目標，而且工作成果沒有達到應有品質水準
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Meets requirements of the job. A solid consistent performer who gets the job done in a
                          participative manner.<br>
                          完成設定的工作目標，可以確實的完成主管交付的工作任務。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Exceeds most requirements of the job. Dose more than normally expected. In most parts of the job
                          the work is truly outstanding. but in all cases it meets the requirements of the job employing
                          participate techniques.<br>
                          績效超越原設定的工作目標，且常有超出預期的良好表現。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Exceeds all requirements of the job. An outstanding performer in terms of every requirement of
                          the job employing participate techniques.<br>
                          表現卓越的績效，除了主管交辦的任務可以表現傑出的完成外，經常以創新主動的表現，超越主管的期望。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions01>
                <div class="w-full ml-3">
                  Reminding:<br>
                  1.Please make sure you had completed reading the guideline before your performance review.<br>
                  2.Employee should fill all the parts which make as <span class="font-black">*</span><br>
                  請注意:<br>
                  1.請在開始填寫本績效評估表前，務必仔細閱讀詳細說明<br>
                  2.表格中有「<span class="font-black">*</span>」符號者，表示需由員工填寫完成
                </div>
              </template>
              <template #partA="{ data }">
                <div class="w-full ml-3">
                  <span class="font-black flex">PART A - SELF PERFORMANCE APPRAISAL 個人績效評估
                    <!-- <ChevronRightIcon
                      class="top-menu__sub-icon"
                      v-show="!foldSetting.partA"
                      @click="fold('partA')"
                    />
                    <ChevronDownIcon
                      class="top-menu__sub-icon"
                      v-show="foldSetting.partA"
                      @click="fold('partA')"
                    /> -->
                  </span>
                  <div>
                    <table class="w-full table-auto w-auto border-collapse border border-gray-400 my-2">
                      <thead>
                        <tr>
                          <th
                            class="border border-gray-400 bg-gray-200 py-2 px-4 flex justify-center font-black"
                            colspan="5"
                          >Objectives Achievement 工作目標達成
                            <span @click="fold('ObjectivesAchievement')">
                              <ChevronRightIcon
                                class="top-menu__sub-icon"
                                v-show="!foldSetting.ObjectivesAchievement"
                              />
                              <ChevronDownIcon
                                class="top-menu__sub-icon"
                                v-show="foldSetting.ObjectivesAchievement"
                              />
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div v-show="foldSetting.ObjectivesAchievement">
                      <div class="w-full ml-3 my-4">
                        A.<span class="font-black">*</span>Beneath each performance objective summarize and results during
                        the
                        review period.(Major 3-5 Items)
                        員工條列三至五項具體完成事項說明，並整理評估期間之達成目標
                      </div>
                      <div class="flex mb-2 my-2">
                        <div class="w-1/3 text-center">完成事項 Things to accomplish</div>
                        <div class="w-1/3 text-center">員工自評 Employee</div>
                        <div class="w-1/3 text-center"><span class="text-red-500 mr-1">*</span>班長/組長或單位主管評核 Rating by Team
                          Leader/Immediate Manager
                        </div>
                      </div>
                      <div
                        v-for="(thing, index) in  data.XmlData.ItemsA "
                        :key="'ItemsA' + thing._XID"
                        class="flex"
                        :class="{ 'bg-blue-50': index % 2 !== 1 }"
                      >
                        <div class=" flex w-1/3  items-center">{{ thing.Completed }}</div>
                        <div class=" flex w-1/3 justify-center items-center">{{ thing.SelfEvaluation }}</div>
                        <div class="flex w-1/3 justify-center items-center">
                          <vxe-select
                            v-model="thing.ManagerEvaluation"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_ItemsA' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="w-full ml-3 my-4">
                        B.<span class="font-black">*</span>Give constructive suggestive suggestions for how performance
                        could be improved
                        就未完事項，提出具提建設性自我改進建議
                      </div>
                      <div
                        v-for="(suggestion, index) in  data.XmlData.ItemsB"
                        :key="'ItemsB' + suggestion._XID"
                        class="flex"
                        :class="{ 'bg-blue-50': index % 2 !== 1 }"
                      >
                        <div class=" flex w-1/2  items-center">{{ index + 1 }}、自我改進建議</div>
                        <div class=" flex w-1/2 justify-center items-center">{{ suggestion.Completed }}</div>
                      </div>
                    </div>
                    <table class="w-full table-auto w-auto border-collapse border border-gray-400 my-4">
                      <thead>
                        <tr>
                          <th
                            class="border border-gray-400 bg-gray-200 py-2 px-4 flex justify-center font-black"
                            colspan="5"
                          >Core Competency Evaluation 核心職能評核
                            <span @click="fold('CoreCompetencyEvaluation')">
                              <ChevronRightIcon
                                class="top-menu__sub-icon"
                                v-show="!foldSetting.CoreCompetencyEvaluation"
                              />
                              <ChevronDownIcon
                                class="top-menu__sub-icon"
                                v-show="foldSetting.CoreCompetencyEvaluation"
                              />
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div v-show="foldSetting.CoreCompetencyEvaluation">
                      <div class="flex justify-end mb-2">
                        <div class="w-1/3 text-center ">員工自評 Employee</div>
                        <div class="w-1/3 text-center"><span class="text-red-500 mr-1">*</span>班長/組長或單位主管評核 Rating by Team
                          Leader/Immediate Manager</div>
                      </div>
                      <div class="flex bg-blue-50">
                        <div class="w-1/3">品質 Quality Conscious<br>
                          持續投注於助益光益包裝工業(股)公司成功之正向、具體效度之工作面向。
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Quality"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Quality' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Quality"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Quality' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-1/3">誠實正直 Integrity<br>
                          行為操守符合高標準之企業守則及價值
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Interity"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Interity' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Interity"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Interity' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="flex bg-blue-50">
                        <div class="w-1/3">專業 Professionalism<br>
                          對於負責職掌範圍職位內容，具備透徹之知識及作業技巧
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Professionalism"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Professionalism' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Professionalism"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Professionalism' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-1/3">持續進步 Contribution to Continuous Improvement<br>
                          工作成果奠基於不斷尋求之創新、挑戰熱枕
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Progress"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Progress' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Progress"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Progress' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="flex bg-blue-50">
                        <div class="w-1/3">解決問題 Problem Solving<br>
                          在不確定或缺乏完整訊息的狀況下，能彈性、效率地達致工作成果
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Problem"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Problem' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Problem"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Problem' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-1/3">團隊精神 Teamwork<br>
                          在團隊組織中，熟諳如何與他人共同完成工作目標
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Teamwork"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Teamwork' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Teamwork"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Teamwork' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                      <div class="flex bg-blue-50">
                        <div class="w-1/3">自動自發 Spontaneity<br>
                          具活力，並自我驅動以尋求成就的工作動能
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.SelfEvaluation_Spontaneity"
                            class="border rounded"
                            clearable
                            disabled
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Self_Spontaneity' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                        <div class="w-1/3 p-2">
                          <vxe-select
                            v-model="data.XmlData.ManagerEvaluation_Spontaneity"
                            class="border rounded"
                            clearable
                            :disabled="data.CurrentSignatureLevel>1"
                          >
                            <vxe-option
                              v-for=" option in standardOption "
                              :key="'Manager_Spontaneity' + option.value"
                              :value="option.value"
                              :label="`${option.label}`"
                            ></vxe-option>
                          </vxe-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #partB>
                <div class="w-full ml-3">
                  <span class="font-black">PART B - Performance Evaluation 整體績效評核
                  </span>
                </div>
              </template>
              <template #fedirections05>
                <div class="font-black">
                  ！在工作中是否遭遇到特別的困難影響您的工作表現? 您的主管可提供那些必要的協助資源嗎?<br>
                  Is there any particular diffculty that has caused negative impact of your performance? What are the
                  resource that your manager could provide to you?
                </div>
              </template>
              <template #column-Overall>
                <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th
                        class="border border-gray-400 bg-gray-200 py-2 px-4 text-center font-black"
                        colspan="5"
                      >Overall Rating 整體自評(請參考下列評分標準：E, D, G, I, C)</th>
                    </tr>
                  </thead>
                </table>
              </template>
              <template #column-Next>
                <div class="w-full ml-3">
                  <span class="font-black">PART C - OBJECTIVE PLAN FOR NEXT REVIEW PERIOD 下次評核之個人工作目標設定</span>
                </div>
              </template>
              <template #column-Signature>
                <div class="w-full ml-3">
                  <span class="font-black">AGREEMENT & SIGNATURE 簽名</span>
                </div>
              </template>
              <!-- 年終績效評估 34 -->

              <!-- 差旅與交際費用申請單 35 -->
              <template #column-TravelItems="{ data }">
                <TravelItemsTable
                  v-if="data.XmlData"
                  ref="TravelItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.TravelItems"
                  :readType="true"
                />
              </template>
              <template #column-EntertainmentItems="{ data }">
                <EntertainmentItemsTable
                  v-if="data.XmlData"
                  ref="EntertainmentItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.EntertainmentItems"
                  :readType="true"
                />
              </template>
              <!-- 差旅與交際費用申請單 35 -->

              <!-- 離職申請與移交確認紀錄 38 -->
              <template #column-LeavingReasonType="{ data }">
                <select-box
                  class="custom-vue-selector"
                  v-if="data.XmlData"
                  v-bind="leavingReasonTypeSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.LeavingReason"
                />
              </template>
              <!-- 離職申請與移交確認紀錄 38 -->

              <!-- 員工試用期滿考核表 39 -->
              <template #directions01-39>
                <div class="w-full ml-3">
                  本表分六大項考核新進員工,每項分四種等級,請仔細評估後在評分欄填入分數,考核總分在 80 分(含)以上,且無任一項為 D 級者為合格正式任用,不合格者,則不予任用或主管得再延長試用期(以三個月為上限)。
                </div>
              </template>
              <template #directions02-39>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          項目
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          考核內容
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          分數
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="10%"
                          rowspan="4"
                        >
                          工作效率
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          A.效率甚高, 工作量超過標準, 且能提前完成
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          15-14
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          B.效率好, 工作量達到標準, 且能按時完成
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          13-11
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          C.工作勤奮, 工作量尚可, 偶爾需要幫助
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          10-7
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          D.效率不高, 工作量未達標準, 經常需要別人幫助
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          6-0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions03-39>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          項目
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          考核內容
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          分數
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="10%"
                          rowspan="4"
                        >
                          工作品質
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          A.工作的處理過程與結果, 均是正確且足以被信賴
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          15-14
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          B.偶爾發生錯誤, 但尚能細心更正, 成果是正確且尚足以被信賴
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          13-11
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          C.有時發生錯誤, 但工作成果尚稱良好
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          10-7
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          D.粗心大意, 時常發生錯誤
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          6-0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions04-39>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          項目
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          考核內容
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          分數
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="10%"
                          rowspan="4"
                        >
                          勤勉負責
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          A.工作勤勉積極, 所有交付的工作皆能完全任務
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          15-14
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          B.工作習慣可靠準時, 很少忽略任何應注意事項
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          13-11
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          C.除偶爾需加以提示之外, 大致可以井然有序的完成工作
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          10-7
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          D.經常忽略或忘記工作, 需要時常檢查或督導
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          6-0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions05-39>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          項目
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          考核內容
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          分數
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="10%"
                          rowspan="4"
                        >
                          忠誠度
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          A.能為公司的利益作最大的設想與努力, 能配合公司之規章制度
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          15-14
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          B.願意為公司的利益作適當程度之貢獻
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          13-11
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          C.能配合公司之規章制度
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          10-7
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          D.不能為公司大體利益著想, 無法配合公司之規章制度
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          6-0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions06-39>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          項目
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          考核內容
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          分數
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="10%"
                          rowspan="4"
                        >
                          服務精神
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          A.對工作有明確的關心與熱忱, 樂意協助他人
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          15-14
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          B.樂意擔任工作並能關心本身的工作
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          13-11
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          C.很少對被分派的工作不滿
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          10-7
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          D.有時不願接受自己被分派的工作, 對主管善意的建議不予理會
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          6-0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions07-39>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          項目
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          考核內容
                        </th>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center">
                          分數
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="10%"
                          rowspan="4"
                        >
                          團隊精神
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          A.與別人共事時, 非常體諒和細心, 樂於助人
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          15-14
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          B.與別人相處融洽, 待人親切有禮, 合作度高
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          13-11
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          C.與別人相處合作情形, 尚稱良好
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          10-7
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          D.與別人相處, 草率依賴, 沒有團隊精神
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          6-0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <!-- 員工試用期滿考核表 39 -->

              <!-- 物性檢測申請表 40 -->
              <template #column-TestingItem="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="physicalPropertiesTestingSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.Items"
                />
              </template>
              <!-- 物性檢測申請表 40 -->

              <!-- 製作樣品通知單項目 43 -->
              <template #column-SampleNotificationFormItems="{ data }">
                <SampleNotificationFormItemsTable
                  v-if="data.XmlData"
                  ref="SampleNotificationFormItemsTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <!-- 製作樣品通知單項目 43 -->

              <!-- 銷貨折讓原因單 44 -->
              <template #column-Invoice="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="invoiceSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.InvoiceId"
                />
              </template>
              <!-- 銷貨折讓原因單 44 -->

              <!-- 來料檢驗 46 -->
              <template #column-CategoryItem="{ data }">
                <CategoryItemTable
                  v-if="data.XmlData"
                  ref="CategoryItemTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.CategoryItems"
                  :readType="true"
                />
              </template>
              <template #column-Items="{ data }">
                <IQCTableItemsTable
                  v-if="data.XmlData"
                  ref="IQCTableItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="true"
                />
              </template>
              <template #column-category="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="categorySelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.CategoryId"
                  @change="changeCategory(data)"
                />
              </template>
              <!-- 來料檢驗 46 -->

              <!-- 品質異常處理單 47 -->
              <!-- <template #column-ErrorSteps="{ data }">
                <select-box v-if="data.XmlData" v-bind="errorStepsSelectorOptions" :readonly="true" :disabled="true" v-model="data.XmlData.ErrorSteps" />
              </template>
              <template #column-DiscoveryUnits="{ data }">
                <select-box v-if="data.XmlData" v-bind="discoveryUnitsSelectorOptions" :readonly="true" :disabled="true" v-model="data.XmlData.DiscoveryUnits" />
              </template> -->
              <template #column-Handling="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="handlingSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.Handling"
                />
              </template>
              <template #column-ResponsibilityUnit="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="responsibilityUnitSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ResponsibilityUnit"
                />
              </template>
              <template #column-RemedialType="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="remedialTypeSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.RemedialType"
                />
              </template>

              <template #column-imgfile="{ data }">
                <DocumentImgFileUpload
                  v-if="data.Id"
                  ref="fileImgGrid"
                  :title="''"
                  v-bind="fileImgModal.uploadOptions"
                  :canUpload="false"
                  :canDelete="false"
                />
              </template>
              <!-- 品質異常處理單 47 -->

              <template #column-hr>
                <div class="border-t border-gray-300"></div>
              </template>
              <!-- 品質異常原因調查表 48 -->
              <template #column-ProblemContentType="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="problemContentTypeSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ProblemContentType"
                />
              </template>
              <template #column-Product="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="productSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ProductId"
                />
              </template>
              <!-- 品質異常原因調查表 48 -->

              <!-- 品質異常原因調查表 49 -->
              <template #column-ContactDepartment="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="contactDepartmentSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.ContactDepartment"
                />
              </template>
              <template #column-MessageType="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="messageTypeSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.MessageType"
                />
              </template>
              <!-- 品質異常原因調查表 49 -->

              <!-- 出差工作日報表 50 -->
              <template #column-BusinessTripDailyReportItems="{ data }">
                <BusinessTripDailyReportItemsTable
                  v-if="data.XmlData"
                  ref="BusinessTripDailyReportItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="readType"
                />
              </template>
              <!-- 出差工作日報表 50 -->
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <SignDataTable
              ref="signTable"
              :aid="row.Id"
            />
            <div class="flex justify-between">
              <div>
                <vxe-button
                  v-if="row.Id && row.ContentType == 31"
                  type="button"
                  status="primary"
                  content="發票資料"
                  @click="exportInvoiceReport"
                ></vxe-button>
                <vxe-button
                  v-if="row.Id && row.ContentType == 31"
                  type="button"
                  status="primary"
                  content="包裝資料"
                  @click="exportPackingListReport"
                ></vxe-button>
              </div>
              <div>
                <vxe-button
                  type="button"
                  status="success"
                  content="核准"
                  @click="signDocument(row, true)"
                ></vxe-button>
                <vxe-button
                  type="button"
                  status="danger"
                  content="否決"
                  @click="signDocument(row, false)"
                ></vxe-button>
                <!-- 能修改的文件type && 狀態=已送出or簽核中 -->
                <!-- <template v-if="needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3)"> -->
                <template v-if="(row.Status == 1 || row.Status == 3 || row.Status == 4)">
                  <vxe-button
                    type="submit"
                    status="primary"
                    content="確認"
                    @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"
                  ></vxe-button>
                </template>
                <template v-else>
                  <vxe-button
                    type="button"
                    status="primary"
                    content="關閉"
                    @click="() => { grid.isModalPopup = false; grid.refresh(); }"
                  ></vxe-button>
                </template>
              </div>
            </div>
            <!-- <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="( option, index ) in  column.filters "
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal
      resize
      v-model="signModal.visible"
      width="70%"
      title="簽核紀錄"
      height="600"
      show-zoom="true"
    >
      <SignatureDataTable
        ref="signGrid"
        v-bind="signModal.gridOptions"
        :modelValue="signModal.visible"
        :sid="signModal.selectedRow.Id"
      />
    </vxe-modal>
    <vxe-modal
      resize
      v-model="flowModal.visible"
      width="70%"
      title="文件流程"
      height="600"
      show-zoom="true"
    >
      <InProgressFlowTable
        ref="flowGrid"
        v-bind="flowModal.gridOptions"
        :modelValue="flowModal.visible"
        :contentType="flowModal.selectedRow.ContentType"
        :read="flowModal.selectedRow.Status != 0"
        :sid="flowModal.selectedRow.Id"
      />
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, watch } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import SignDataTable from "@/components/process/SignDataTable.vue";

import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import CheckRecordItemsTable from "@/components/document/CheckRecordItemsTable.vue";
import IQCItemsTable from "@/components/document/IQCItemsTable.vue";
import TypeAProductionCheckItemsTable from "@/components/document/TypeAProductionCheckItemsTable.vue";
import WinderItemsTable from "@/components/document/WinderItemsTable.vue";
import RollItemsTable from "@/components/document/RollItemsTable.vue";
import ProductionPackageQCItemsTable from "@/components/document/ProductionPackageQCItemsTable.vue";
import SalesItemsTable from "@/components/document/SalesItemsTable.vue";
import StaffItemsTable from "@/components/document/StaffItemsTable.vue";
import TrackingItemsTable from "@/components/document/TrackingItemsTable.vue";
import FinalQualityInspectionReportItemsTable from "@/components/document/FinalQualityInspectionReportItemsTable.vue";
import HumidityCheckRecordItemsTable from "@/components/document/HumidityCheckRecordItemsTable.vue";
import JobTickeDocumentItemsTable from "@/components/document/JobTickeDocumentItemsTable.vue";
import RemittanceSlipItemsTable from "@/components/document/RemittanceSlipItemsTable.vue";
import PerformanceEvaluationItemsATable from "@/components/document/PerformanceEvaluationItemsATable.vue";
import PerformanceEvaluationItemsBTable from "@/components/document/PerformanceEvaluationItemsBTable.vue";
import EntertainmentItemsTable from "@/components/document/EntertainmentItemsTable.vue";
import TravelItemsTable from "@/components/document/TravelItemsTable.vue";
import SampleNotificationFormItemsTable from "@/components/document/SampleNotificationFormItemsTable.vue";
import CategoryItemTable from "@/components/document/CategoryItemTable.vue";
import IQCTableItemsTable from "@/components/document/IQCTableItemsTable.vue";
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'
import formatDate from 'xe-utils/toDateString';
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";
import SignatureDataTable from "@/components/document-flow/SignatureDataTable.vue";
import BusinessTripDailyReportItemsTable from "@/components/document/BusinessTripDailyReportItemsTable.vue";
import InProgressFlowTable from "@/components/document-flow/InProgressFlowTable.vue";
import DocumentImgFileUpload from "@/components/file/DocumentImgFileUpload.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    SignDataTable,
    SalesItemsTable,
    StaffItemsTable,
    TrackingItemsTable,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    CheckRecordItemsTable,
    IQCItemsTable,
    TypeAProductionCheckItemsTable,
    WinderItemsTable,
    RollItemsTable,
    ProductionPackageQCItemsTable,
    FinalQualityInspectionReportItemsTable,
    HumidityCheckRecordItemsTable,
    JobTickeDocumentItemsTable,
    RemittanceSlipItemsTable,
    PerformanceEvaluationItemsATable,
    PerformanceEvaluationItemsBTable,
    EntertainmentItemsTable,
    TravelItemsTable,
    SampleNotificationFormItemsTable,
    CategoryItemTable,
    IQCTableItemsTable,
    DocumentFileUpload,
    SignatureDataTable,
    BusinessTripDailyReportItemsTable,
    InProgressFlowTable,
    DocumentImgFileUpload,
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref("");
    const readType = ref(false);
    const filereadType = ref(false);
    const needSave = [38, 34, 39];
    const user = CloudFun.current?.user;
    const gridOptions: GridOptions = {
      id: 'document',
      title: '建立文件申請',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: true,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        // { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        // { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentPosition.Name', title: '目前簽核職務', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        {
          title: '文件流程',
          width: '100',
          slots: { default: "flow" }, align: 'center'
        },
        {
          title: '簽核紀錄',
          width: '100',
          slots: { default: "sign" }, align: 'center'
        },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedType.value !== "") {
            params.condition!.and("ContentType", Operator.Equal, selectedType.value);
          }
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          console.log("user.Positions", model.user.Positions);
          //params.condition!.and("CurrentPositionId", Operator.IsNotNull).and("CurrentPositionId", Operator.In, model.user.Positions).and("Status.Value", Operator.NotEqual, 0).and("Status.Value", Operator.NotEqual, 5).and("Status.Value", Operator.NotEqual, 4).and("Status.Value", Operator.NotEqual, 32); //送出狀態 .and("Status.Value", Operator.NotEqual, 0);
          params.condition!.and("CurrentPositionId", Operator.IsNotNull).and("CurrentPositionId", Operator.In, model.user.Positions).and("Status.Value", Operator.NotIn, [0, 4, 5, 32]); //送出狀態 .and("Status.Value", Operator.NotEqual, 0);and("Status.Value", Operator.NotEqual, 0);

          if (user && !user.IsAdmin && !user.isUnitManager && !user.isDepManager && !user.isHR && !user.isDocM && !user.isAcc) {
            params.condition = new Condition("SubmitterId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          if (user && user.userDepartmentId && user.userDepartmentId !== 3 && !user.isHR && !user.isDocM && !user.isAcc && (user.isUnitManager || user.isDepManager)) {
            if (user.isDepManager) {
              console.log('isDepManager', user.userManagerDep);
              console.log(user.userManagerDep);
              //params.condition = new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
              params.condition!.or(new Condition("IsDepManager", Operator.Equal, user.userManagerDep));
              //params.condition = new Condition("Submitter.DepartmentId", Operator.IsNotNull)new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
            }
            if (user.isUnitManager) {
              console.log('isUnitManager');
              params.condition!.or(new Condition("IsUnitManager", Operator.Equal, user.userManagerUnit));
            }
          }
          if (user && user.isHR && (user.isUnitManager || user.isDepManager)) {
            if (user.isDepManager) {
              console.log('isDepManager', user.userManagerDep);
              console.log(user.userManagerDep);
              //params.condition = new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
              params.condition!.or(new Condition("IsDepManager", Operator.Equal, user.userManagerDep));
              //params.condition = new Condition("Submitter.DepartmentId", Operator.IsNotNull)new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
            }
            if (user.isUnitManager) {
              console.log('isUnitManager');
              params.condition!.or(new Condition("IsUnitManager", Operator.Equal, user.userManagerUnit));
            }
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    // const contentTypeChange = (item: any) => {
    //   if (item.data.ContentType >= 0) {
    //     var contentTypeOption = getformOption(item.data.ContentType) as any;
    //     formOptions.items?.push({
    //       field: 'ApplicationDate',
    //       title: '填表(申請)日期',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
    //     })
    //     console.log(form.value)
    //     //Array.prototype.push.apply(formOptions.items, contentTypeOption.items);
    //     //Array.prototype.push.apply(formOptions.rules, contentTypeOption.items);

    //   }
    // }

    const form = ref<any>();
    const rowData = ref<any>({ XmlData:{} }); 

    const defaultFormOptions: VxeFormProps = {
      items: [
        // {
        //   field: 'Title',
        //   title: '名稱',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', readonly: true },
        //     attrs: { type: 'text' }
        //   }
        // },
        // {
        //   field: 'SubmittingTime',
        //   title: '提交時間',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        // },
        // {
        //   field: 'Type',
        //   title: '文件類型',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true, readonly: true }, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // // {
        // //   field: 'ContentType',
        // //   title: '表單類型',
        // //   span: 12,
        // //   itemRender: { name: "$select", props: { disabled: true, readonly: true }, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // // },
        // {
        //   field: 'SubmitterId',
        //   title: '申請人',
        //   span: 12,
        //   slots: { default: "column-submitter" }
        // },
        // {
        //   field: 'CurrentSignatureLevel',
        //   title: '目前簽核層級',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '', type: 'number', disabled: true },
        //   }
        // },
        // {
        //   field: 'Status',
        //   title: '簽核狀態',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = (row: any, callback: any) => {
      row.Status = 0;
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;
      callback();
    }

    const invoice = ref<any>();
    const packingList = ref<any>();
    const onGridEdit = async (row: any, callback: any) => {
     
      SignDocumentRow.value = null
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        console.log("Process-CurrentSignatureLevel:", row.CurrentSignatureLevel);
        console.log("Process-Status:", row.Status);
        console.log("Process-isUnitManager:", row.isUnitManager);
        console.log("Process-isDepManager:", row.isDepManager);
        console.log("CloudFun.current:", CloudFun.current);
        console.log("CloudFun.current?.user.isDepManager:", user);
        console.log("CloudFun.current?.user.isUnitManager:", CloudFun.current?.user.isUnitManager);
        
        var contentTypeOption = getformOption(row.ContentType, model, true, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? row.CurrentSignatureLevel : 0, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? row.CurrentPositionId : 0, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? row.isUnitManager : false, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? user?.isDepManager : false) as any;
        //console.log(contentTypeOption)
        const defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          // console.log(key, contentTypeOption.rules[key])
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;
        //console.log(row)

        if (row.ContentType == 26) {
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (!row.XmlData.Year)
            row.XmlData.Year = year;
          if (!row.XmlData.Month)
            row.XmlData.Month = month;
        }
        if (row.ContentType == 31) {
          invoice.value = row.XmlData.InvoiceId ? await model?.dispatch('invoice/find', row.XmlData.InvoiceId) : null;
          const condition = new Condition("InvoiceNumber", Operator.Equal, row.XmlData.InvoiceNumber);
          var list = row.XmlData.InvoiceNumber ? await model?.dispatch("packingList/query", { condition }) : null;
          packingList.value = list?.[0];
        }
        // if(row.ContentType == 47 || row.ContentType == 49 || row.ContentType == 39) {
        if (fileModal && row.ContentType != 47 && row.ContentType != 48){
          fileModal.show(row);
          console.log('user',user)
          if (user?.isDepManager || user?.isUnitManager) {
            filereadType.value = true;
          }else{
            filereadType.value = false;
          }
        } 
        // }

        if (fileImgModal && (row.ContentType == 47 || row.ContentType == 48)) {
          fileImgModal.show(row);
        }
        if(row.ContentType == 39){
          Object.assign(rowData.value, row);
        }

        // for (const key in row.XmlData) {
        //   formOptions.items?.push({
        //     field: key,
        //     title: key,
        //     span: 12,
        //     itemRender: {
        //       name: '$input',
        //       props: { placeholder: '請輸入文字' },
        //     }
        //   })
        // }
      }
      callback()
    }

    watch(() => [
      rowData.value.XmlData?.Teamwork,
      rowData.value.XmlData?.ServiceAttitude,
      rowData.value.XmlData?.Loyalty,
      rowData.value.XmlData?.Diligence,
      rowData.value.XmlData?.Quality,
      rowData.value.XmlData?.Efficiency,
      rowData.value.XmlData?.Attendance], async(value) => {
        // console.log('in watch');
        // console.log(rowData.value.XmlData)
        if(rowData.value.XmlData) {
          rowData.value.XmlData.TotalScore = parseInt(rowData.value.XmlData.Teamwork) + parseInt(rowData.value.XmlData.ServiceAttitude) + 
            parseInt(rowData.value.XmlData.Loyalty) + parseInt(rowData.value.XmlData.Diligence) + 
            parseInt(rowData.value.XmlData.Quality) + parseInt(rowData.value.XmlData.Efficiency) + parseInt(rowData.value.XmlData?.Attendance);
          if(isNaN(rowData.value.XmlData.TotalScore)) rowData.value.XmlData.TotalScore = 0;
          if(getEvaluationResult()) {
            rowData.value.XmlData.EvaluationResult = '正式任用';
          }else{
            rowData.value.XmlData.EvaluationResult = '不予任用';
          }
        }
    }, { immediate: true });

    const getEvaluationResult = () => {
      console.log('rowData.value.XmlData.Teamwork',rowData.value.XmlData.Teamwork);
      console.log('rowData.value.XmlData.ServiceAttitude',rowData.value.XmlData.ServiceAttitude);
      console.log('rowData.value.XmlData.Loyalty',rowData.value.XmlData.Loyalty);
      console.log('rowData.value.XmlData.Diligence',rowData.value.XmlData.Diligence);
      console.log('rowData.value.XmlData.Efficiency',rowData.value.XmlData.Efficiency);
      console.log('rowData.value.XmlData.Quality',rowData.value.XmlData.Quality);
      console.log('rowData.value.XmlData.TotalScore',rowData.value.XmlData.TotalScore);
      console.log('SS',parseInt(rowData.value.XmlData.Teamwork) > 6 &&
              parseInt(rowData.value.XmlData.ServiceAttitude) > 6 &&
              parseInt(rowData.value.XmlData.Loyalty) > 6 &&
              parseInt(rowData.value.XmlData.Diligence) > 6 &&
              parseInt(rowData.value.XmlData.Efficiency) > 6 &&
              parseInt(rowData.value.XmlData.Quality) > 6 &&
              rowData.value.XmlData.TotalScore >= 80);
      return parseInt(rowData.value.XmlData.Teamwork) > 6 &&
              parseInt(rowData.value.XmlData.ServiceAttitude) > 6 &&
              parseInt(rowData.value.XmlData.Loyalty) > 6 &&
              parseInt(rowData.value.XmlData.Diligence) > 6 &&
              parseInt(rowData.value.XmlData.Efficiency) > 6 &&
              parseInt(rowData.value.XmlData.Quality) > 6 &&
              rowData.value.XmlData.TotalScore >= 80;
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();
    const CheckRecordItemsTable = ref<any>();
    const IQCItemsTable = ref<any>();
    const TypeAProductionCheckItemsTable = ref<any>();
    const WinderItemsTable = ref<any>();
    const RollItemsTable = ref<any>();
    const ProductionPackageQCItemsTable = ref<any>();
    const FinalQualityInspectionReportItemsTable = ref<any>();
    const SalesItemsTable = ref<any>();
    const StaffItemsTable = ref<any>();
    const TrackingItemsTable = ref<any>();
    const HumidityCheckRecordItemsTable = ref<any>();
    const JobTickeDocumentItemsTable = ref<any>();
    const RemittanceSlipItemsTable = ref<any>();
    const PerformanceEvaluationItemsATable = ref<any>();
    const PerformanceEvaluationItemsBTable = ref<any>();
    const EntertainmentItemsTable = ref<any>();
    const TravelItemsTable = ref<any>();
    const SampleNotificationFormItemsTable = ref<any>();
    const BusinessTripDailyReportItemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      if (row.Id) {
        if (row.ContentType == 2) {
          var invoiceQuarterlyItems = itemsTable?.value.getData();
          console.log(invoiceQuarterlyItems)
          if (invoiceQuarterlyItems && invoiceQuarterlyItems.length > 0)
            row.XmlData.InvoiceQuarterlyItems = invoiceQuarterlyItems;
        }
      }
      if (row.ContentType == 34) {
        // const { ManagerEvaluation_Quality, ManagerEvaluation_Interity, ManagerEvaluation_Professionalism, ManagerEvaluation_Progress, ManagerEvaluation_Problem, ManagerEvaluation_Teamwork, ManagerEvaluation_Spontaneity } = row.XmlData
        // const isMissingEvaluation = !ManagerEvaluation_Quality || !ManagerEvaluation_Interity || !ManagerEvaluation_Professionalism || !ManagerEvaluation_Progress || !ManagerEvaluation_Problem || !ManagerEvaluation_Teamwork || !ManagerEvaluation_Spontaneity;
        // const isManagerEvaluationPresent = row.XmlData.ItemsA.every((item: any) => item.ManagerEvaluation !== null);
        // if (!isManagerEvaluationPresent) {
        //   alert('請確認工作目標達成都有進行評核!!');
        // } else if (isMissingEvaluation) {
        //   alert('請確認核心職能項目都有進行評核!!');
        // } else {
        //   submit();
        // }
        submit();
        // if (isMissingEvaluation || !isManagerEvaluationPresent) alert('please complete the Core Competency Evaluation and the Objectives Achievement')
        // else submit();
      } else {
        submit();
      }
    }

    const signTable = ref<any>();
    const SignDocumentRow = ref<any>(null);
    const signDocument = async (row: any, approved: boolean) => {
      if (row.ContentType == 34) {
        const { ManagerEvaluation_Quality, ManagerEvaluation_Interity, ManagerEvaluation_Professionalism, ManagerEvaluation_Progress, ManagerEvaluation_Problem, ManagerEvaluation_Teamwork, ManagerEvaluation_Spontaneity } = row.XmlData
        const isMissingEvaluation = !ManagerEvaluation_Quality || !ManagerEvaluation_Interity || !ManagerEvaluation_Professionalism || !ManagerEvaluation_Progress || !ManagerEvaluation_Problem || !ManagerEvaluation_Teamwork || !ManagerEvaluation_Spontaneity;
        const isManagerEvaluationPresent = row.XmlData.ItemsA.every((item: any) => item.ManagerEvaluation !== null);
        if(approved){
          if (!isManagerEvaluationPresent) {
            alert('請確認工作目標達成都有進行評核!!');
            return false;
          } else if (isMissingEvaluation) {
            alert('請確認核心職能項目都有進行評核!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 2 && !row.XmlData.Overall_Manager){
            alert('請進行部門主管評核!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 2 && (row.XmlData.Next_Manager == null || row.XmlData.Next_Manager == undefined)){
            alert('請輸入進行部門主管意見!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 3 && !row.XmlData.Overall_VicePresident){
            alert('請進行副總經理評核!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 4 && !row.XmlData.Overall_President){
            alert('請進行總經理評核!!');
            return false;
          }
        }
        console.log('row',row);
        console.log('user',user);
      }
      var signData = await signTable.value.submitData();
      var pass = confirm(`確定要${approved ? '核准' : '否決'}此文件嗎?`);
      if (signData && pass) {
        if(row.ContentType == 34 || row.ContentType == 39 || row.ContentType == 38){
          SignDocumentRow.value = { ...row, Approve: approved };
          await form.value.validate((errMap: any) => { if (errMap === undefined) form.value.$emit('submit') })
        }else{
          var result = {
            Type: row.ContentType, //文件內容類型
            DocumentId: row.Id, //文件Id
            Level: row.CurrentSignatureLevel, //當前簽核等級
            SignatoryId: CloudFun.user.Id, //簽核人Id
            Approved: approved, //signData.Approved, //是否通過
            Comment: signData.Comment, //簽核意見
            PositionId: row.CurrentPositionId, //0721 補上簽核階級
          }
        // console.log(result);
          try {
            await model?.dispatch('document/approve', result);
            CloudFun.send("info", {
              subject: "執行成功",
              content: "完成簽核",
            });
            grid.value.isModalPopup = false;
            await grid.value.refresh();
          } catch (e: any) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: e,
            });
          } finally {
            SignDocumentRow.value = null
          }
        }

      }
    }

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    /** 權限開放申請單使用 */
    const requestContentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 物性檢測申請表使用 */
    const physicalPropertiesTestingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 來料檢驗使用 */
    const categorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("iqcCategory/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          params.sortings!.push({ column: 'Ordinal', order: 0 });
          return model!.dispatch("iqcCategory/query", params) // eslint-disable-line
        }
      }
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const exportInvoiceReport = (row: any) => {
      //替換API_URL
      if (invoice.value && invoice.value.Id) {
        window.open(`https://report.imp.cloudfun.app/api/ReportView/InvoiceReportView?invId=${invoice.value.Id}`)
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無法取得資料Id",
        });
      }
    }

    const exportPackingListReport = (row: any) => {
      //替換API_URL
      if (packingList.value && packingList.value.Id) {
        window.open(`https://report.imp.cloudfun.app/api/ReportView/PackingListReportView?packingId=${packingList.value.Id}`)
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無法取得資料Id",
        });
      }
    }

    const problemContentType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const problemContentTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(problemContentType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: problemContentType, totalCount: 1 }));
        },
      },
    };

    const remedialType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const remedialTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(remedialType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: remedialType, totalCount: 1 }));
        },
      },
    };

    const handling = [
      { Id: '1', Name: '要求改善' },
      { Id: '2', Name: '客訴索賠' },
      { Id: '3', Name: '其它' },
    ]
    const handlingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(handling.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: handling, totalCount: 1 }));
        },
      },
    };

    const responsibilityUnit = [
      { Id: '1', Name: '分條' },
      { Id: '2', Name: '覆卷' },
      { Id: '3', Name: '裁切' },
      { Id: '4', Name: '包裝' },
      { Id: '5', Name: '倉管' },
      { Id: '6', Name: '品管' },
      { Id: '7', Name: '供應商' },
      { Id: '8', Name: '業務' },
      { Id: '9', Name: '採購' },
      { Id: '10', Name: '生管' },
      { Id: '11', Name: '其它' },
    ]
    const responsibilityUnitSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(responsibilityUnit.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: responsibilityUnit, totalCount: 1 }));
        },
      },
    };

    const contactDepartment = [
      { Id: '1', Name: 'A.業務部' },
      { Id: '2', Name: 'B.管理部' },
      { Id: '3', Name: 'C.生管組' },
      { Id: '4', Name: 'D.品管組' },
      { Id: '5', Name: 'E.分條組' },
      { Id: '6', Name: 'F.手動分條組' },
      { Id: '7', Name: 'G.切卷組' },
      { Id: '8', Name: 'H.包裝組' },
      { Id: '9', Name: 'I.切管組' },
      { Id: '10', Name: 'J.倉庫組' },
      { Id: '11', Name: 'K.廠務組' },
      { Id: '12', Name: 'L.採購組' },
    ]
    const contactDepartmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(contactDepartment.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: contactDepartment, totalCount: 1 }));
        },
      },
    };

    const messageType = [
      { Id: '1', Name: '一般訊息溝通' },
      { Id: '2', Name: '急件' },
      { Id: '3', Name: '需求' },
      { Id: '4', Name: '通知' },
    ]
    const messageTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(messageType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: messageType, totalCount: 1 }));
        },
      },
    };

    const productSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Color",
          title: "顏色",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          return model!.dispatch("product/query", params)
        }
      }
    };

    const signGrid = ref<any>()
    const signModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition().and("DocumentId", Operator.Equal, signModal.selectedRow.Id)
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = signGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("signatureData/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('signatureData/query') : undefined,
          save: undefined
        }
      } as GridOptions,
      show(row: any) {
        signModal.visible = true;
        signModal.selectedRow = row;
        if (signGrid.value) signGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition("DataMode", Operator.Equal, 0).and("DocumentId", Operator.Equal, flowModal.selectedRow.Id).and("NotifyOnly", Operator
              .Equal, false
            )
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = flowGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("inProgressFlow/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('inProgressFlow/query') : undefined,
          save: model ? (params) => model.dispatch('inProgressFlow/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        flowModal.visible = true;
        flowModal.selectedRow = row;
        if (flowGrid.value) flowGrid.value.refresh();
      }
    })

    /** 權限開放申請單使用 */
    const leavingReasonTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.LeavingReasonType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.LeavingReasonType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const invoiceSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "InvoiceNumber",
      valueField: "Id",
      columns: [
        {
          field: "InvoiceNumber",
          title: "Invoice No",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("invoice/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("invoice/query", params) // eslint-disable-line
        }
      }
    };

    const fileImgGrid = ref<any>()
    const fileImgModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition().and("DocumentId.Value", Operator.Equal, fileImgModal.selectedRow.Id);
            // .and("Type", Operator.Equal, 3).and("Type", Operator.Equal, 2)
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          async insert(formData: FormData, type: any) {
            console.log(type)
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileImgModal.selectedRow.Id, fileType: type });
          },
          async delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileImgModal.selectedRow = row;
        if (fileImgGrid.value) fileImgGrid.value.refresh();
      }
    })

    const foldSetting = ref({
      "PartA": true,
      "PartB": true,
      "PartC": true,
      "ObjectivesAchievement": true,
      "CoreCompetencyEvaluation": true,
      "OverallRating": true,
      "Objectives_Achievement": true,
    })

    const standardOption = ref([
      { value: "E", label: "E" },
      { value: "D", label: "D" },
      { value: "G", label: "G" },
      { value: "I", label: "I" },
      { value: "C", label: "C" }
    ])

    const fold = (target: string) => {
      (foldSetting.value as any)[target] = !(foldSetting.value as any)[target];
    };

    const onSaveSuccess = async () => {
      let signData = await signTable.value.submitData();
      if (SignDocumentRow.value) {
        let result = {
          Type: SignDocumentRow.value.ContentType, //文件內容類型
          DocumentId: SignDocumentRow.value.Id, //文件Id
          Level: SignDocumentRow.value.CurrentSignatureLevel, //當前簽核等級
          SignatoryId: CloudFun.user.Id, //簽核人Id
          Approved: SignDocumentRow.value.Approve, //signData.Approved, //是否通過
          Comment: signData.Comment, //簽核意見
          PositionId: SignDocumentRow.value.CurrentPositionId, //0721 補上簽核階級
        }
        try {
          await model?.dispatch('document/approve', result);
          CloudFun.send("info", {
            subject: "執行成功",
            content: "完成簽核",
          });
          grid.value.isModalPopup = false;
          await grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        } finally {
          SignDocumentRow.value = null
        }
      }
    }

    return {
      grid,
      form,
      gridOptions,
      formOptions,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      signDocument,
      signTable,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      CheckRecordItemsTable,
      IQCItemsTable,
      TypeAProductionCheckItemsTable,
      WinderItemsTable,
      RollItemsTable,
      ProductionPackageQCItemsTable,
      FinalQualityInspectionReportItemsTable,
      SalesItemsTable,
      TrackingItemsTable,
      StaffItemsTable,
      HumidityCheckRecordItemsTable,
      JobTickeDocumentItemsTable,
      RemittanceSlipItemsTable,
      jobTickeSelectorOptions,
      customerSelectorOptions,
      requestContentSelectorOptions,
      PerformanceEvaluationItemsATable,
      PerformanceEvaluationItemsBTable,
      EntertainmentItemsTable,
      TravelItemsTable,
      physicalPropertiesTestingSelectorOptions,
      SampleNotificationFormItemsTable,
      categorySelectorOptions,
      invoice,
      packingList,
      formatDate,
      formatAmount,
      fileModal,
      fileGrid,
      exportInvoiceReport,
      exportPackingListReport,
      remedialTypeSelectorOptions,
      problemContentTypeSelectorOptions,
      handlingSelectorOptions,
      responsibilityUnitSelectorOptions,
      contactDepartmentSelectorOptions,
      messageTypeSelectorOptions,
      productSelectorOptions,
      signGrid,
      signModal,
      BusinessTripDailyReportItemsTable,
      flowGrid,
      flowModal,
      leavingReasonTypeSelectorOptions,
      needSave,
      invoiceSelectorOptions,
      fileImgGrid,
      fileImgModal,
      standardOption,
      foldSetting,
      fold,
      onSaveSuccess,
      SignDocumentRow,
      filereadType,
      readType,
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
