<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="完成事項 Things to accomplish" field="Completed" :item-render="{}" span="24">
          <template #default="{ data }">
            <!-- <vxe-input type="text" v-model="data.Completed" placeholder="請輸入文字" clearable></vxe-input> -->
            <vxe-textarea placeholder="請輸入完成事項" resize="none" v-model="data.Completed" :autosize="{ minRows: 4, maxRows: 8 }" />
          </template>
        </vxe-form-item>
        <vxe-form-item title="員工自評 Rating by Employee" field="SelfEvaluation" :item-render="{}" span="12">
          <template #default="{ data }">
            <!-- <vxe-input type="text" v-model="data.SelfEvaluation" placeholder="(E,D,G,I,C)" clearable></vxe-input> -->
            <vxe-select v-model="data.SelfEvaluation" placeholder="類型" clearable>
              <vxe-option v-for="item in evaluation" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <!-- <vxe-form-item title="班長/組長或單位主管評核  Rating by Team Leader/Immediate Manager" field="ManagerEvaluation" :item-render="{}" span="12">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.ManagerEvaluation" placeholder="(E,D,G,I,C)" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '加入'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Ordinal" title="項次 No" sortable>
      </vxe-column>
      <vxe-column field="Completed" title="完成事項 Things to accomplish" sortable>
      </vxe-column>
      <vxe-column field="SelfEvaluation" title="員工自評 Employee" sortable>
      </vxe-column>
      <vxe-column field="ManagerEvaluation" title="班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager" sortable>
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
    editType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        // console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          table.formData.Ordinal = getNextOrdinal();
          await grid.value.insert(table.formData);
        }
        table.reset();
        await grid.value.sort("Ordinal", 'asc');
      },
      rules: {
        SelfEvaluation: [{ required: true }],
        Completed: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        console.log(props.data)
        if(props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const getNextOrdinal = () => {
      const data = grid.value.getTableData().fullData;
      if(!data || data.length == 0) return 1; 
      var maxOrdinal = Math.max(...data.map((e: any) => e.Ordinal));
      return (maxOrdinal ?? 0) + 1;
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    const evaluation = [
      { label: "E", value: "E" },
      { label: "D", value: "D" },
      { label: "G", value: "G" },
      { label: "I", value: "I" },
      { label: "C", value: "C" }
    ]

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      evaluation,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
