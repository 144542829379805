
/* eslint-disable */
import CloudFun, { defineComponent, ref, Condition, Operator, onMounted } from '@cloudfun/core'
import VXETable from 'vxe-table'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    loading: VueLoading,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    const checkPermissions = (key: string) => {
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canImport = checkPermissions("Administrator");

    const exportReport = async (item: any) => {
      console.log('exportReport click');
      try {
        isLoading.value = true;
        console.log(item);
        if(item) {
          console.log('if(item) true');
          // console.log(startDateFilter.value, endDateFilter.value, exDateFilter.value, CustomerId.value, yearFilter.value);
          var url = `${item.Url}?yearStr=${yearFilter.value ?? ''}&weighting=${weighting.value ?? ''}`
          window.open(url);
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "錯誤的報表網址",
          });
        }
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const startDateFilter = ref<Date>();
    const endDateFilter = ref<Date>();
      const exDateFilter = ref<Date>();
    const CustomerId = ref(null);
    const EmployeeId = ref(null);
    const yearFilter = ref(null);
    const weighting = ref(null);
    var ReportList = ref<any[]>([]);
    var list = ref<any[]>([
      { Name: '結算表', Rule: 1, Url: 'https://report.imp.cloudfun.app/api/ReportView/PerformanceEvaluationScoresReportView', Type: 1 },
      { Name: '匯出', Rule: 2, Url: 'https://report.imp.cloudfun.app/api/ReportView/AllPerformanceEvaluationReportView', Type: 1 },
    ]);

    onMounted(() => {
      list.value.forEach((e: any) => {
        ReportList.value.push(e);
      })
      console.log(ReportList.value)
    }) 

    const search = async() => {
      // 參數 startDate endDate CustomerId EmployeeId
      try {
        console.log('search');
        isLoading.value = true;
        ReportList.value = [];
        list.value.forEach((e: any) => {
          // 權限比對 @blur="search" 
          //console.log(startDateFilter.value, endDateFilter.value, exDateFilter.value, CustomerId.value, yearFilter.value);
          e.Url = `https://report.imp.cloudfun.app/api/ReportView/PerformanceEvaluationScoresReportView?yearStr=${yearFilter.value ?? ''}&weighting=${yearFilter.value ?? ''}`
          ReportList.value.push(e);
        })
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }      
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const employeeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      model,
      canImport,
      isLoading,
      exportReport,
      endDateFilter,
      startDateFilter,
      exDateFilter,
      yearFilter,
      search,
      customerSelectorOptions,
      CustomerId,
      employeeSelectorOptions,
      EmployeeId,
      ReportList,
      weighting
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
