<template>
  <loading loader="spinner"
    :active="isLoading"
    :can-cancel="true"
    :is-full-page="true"></loading>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">出貨管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入出貨" />
          </div>
          <div class="p-1 mr-3 flex items-center">
                <div class="mr-2">起訖日期</div>
                  <vxe-input class="w-36" v-model="startDateFilter" clearable placeholder="開始日期" type="date">
                  </vxe-input>
                  <FontAwesome class="h-2 mx-1 text-gray-400" icon="minus"></FontAwesome>
                  <vxe-input class="w-36" v-model="endDateFilter" clearable placeholder="結束日期" type="date">
                  </vxe-input>
              </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
          @edit="onGridEdit"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-Items="{ data }">
                <ShipmentTable ref="itemsTable" :aid="data.Id" @refresh="refresh" @updateDetail="updateDetail" :data="data.Items" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <div class="flex justify-between">
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="匯出" @click="exportExcel(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印清表" @click="exportReport(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印甲表(一)" @click="exportWorksheetA(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印甲表(二)" @click="exportWorksheetB(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印甲表(三)" @click="exportWorksheetE(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印乙表(一)" @click="exportWorksheetC(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印乙表(二)" @click="exportWorksheetC2(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印乙表(三)" @click="exportWorksheetC3(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="列印丙表" @click="exportWorksheetD(row)"></vxe-button>
              </div>
              <div>
                <vxe-button v-if="row.Id" type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"></vxe-button>
                <!-- <vxe-button v-if="!row.Id" type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
                <vxe-button v-if="!row.Id" type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
                <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
                <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
              </div>
            </div>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString'
import ShipmentTable from "@/components/import-data/ShipmentTable.vue";
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    ShipmentTable,
    loading: VueLoading,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    const startDateFilter = ref<Date>();
    const endDateFilter = ref<Date>();
    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("OrderEstimatedShipmentListRecordCreate");
    const canUpdate = checkPermissions("OrderEstimatedShipmentListRecordUpdate");
    const canDelete = checkPermissions("OrderEstimatedShipmentListRecordDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '出貨管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: true,
      canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '出貨管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '出貨管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Number',
          title: '編號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'RFNo',
          title: '退稅案件編號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'EDNo',
          title: '出口報單號碼',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('shipment/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('shipment/query') : undefined,
        save: model ? (params) => model.dispatch('shipment/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, height: 850, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        {
          field: 'RefundTaxDateTime',
          title: '申請沖退稅日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'EDNo',
          title: '出口報單號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        {
          field: 'SalesDate',
          title: '銷售日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'SalesNumber',
          title: '銷售編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        {
          field: 'CurrencyType',
          title: '幣別',
          span: 12,
          itemRender: { name: '$select', props: { clearable: true }, options: [
                    { value: 0, label: '台幣' },
                    { value: 1, label: '美金' },
                    { value: 2, label: '日幣' },
                    { value: 3, label: '人民幣' },
                    { value: 4, label: '歐元' },
                ]}
        },
        {
          field: 'ExchangeRate',
          title: '匯率',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入匯率', disabled: false } }
        },
        {
          field: 'RFNo',
          title: '退稅案件編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        // {
        //   field: 'RefundTaxDocNo',
        //   title: '退稅案件編號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        // },
        // {
        //   field: 'ExportDocNo',
        //   title: '出口報單號碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        // },
        {
          field: 'CheckAmount',
          title: '核退金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'ActualIssuedAmount',
          title: '實際核發金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        // {
        //   field: 'TotleActualIssuedAmount',
        //   title: '實際核發總金額',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        // },
        {
          field: 'PaymentDate',
          title: '收款日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ContainerFee',
          title: '櫃費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'VenueFee',
          title: '櫃場運費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'DocumentFee',
          title: '文件費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'ServicesFee',
          title: '推廣貿易服務費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'ApproveDate',
          title: '放行日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'FOBPrice',
          title: '出口FOB價格',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'PaperOrElectronic',
          title: '紙本或電子',
          span: 12,
          itemRender: { name: '$select', props: { clearable: true }, options: [
                    { value: "紙本", label: '紙本' },
                    { value: "電子", label: '電子' },
                ]}
        },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-Items' }
        },
      ],
      rules: {
        Number: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      if(items)
        row.Items = items;
      submit();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('shipment/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "出貨資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }
    //清表
    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/ExportDeclarationReportView?recordId=${row.Id}`)
    }
    //甲表一聯
    const exportWorksheetA = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReportView?recordId=${row.Id}`)
    }//甲表二聯
    const exportWorksheetB = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport2View?recordId=${row.Id}`)
    }
    //乙表
    const exportWorksheetC = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport3View?recordId=${row.Id}`)
    }  
    //乙表
    const exportWorksheetC2 = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport32View?recordId=${row.Id}`)
    }    
    //乙表
    const exportWorksheetC3 = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport33View?recordId=${row.Id}`)
    }      
    //丙表
    const exportWorksheetD = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport4View?recordId=${row.Id}`)
    }
    //甲表三聯
    const exportWorksheetE = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport22View?recordId=${row.Id}`)
    }
    const refresh = () => {
      grid.value.refresh();
    }

    const updateDetail = (actualIssuedAmount: any, checkAmount: any) => {
      if (!currentRow.value) return;      
      currentRow.value.ActualIssuedAmount = actualIssuedAmount;
      currentRow.value.CheckAmount = checkAmount;
      //console.log(actualIssuedAmount, checkAmount);
    }

    const currentRow = ref<any>(null);
    const onGridEdit = async(row: any, callback: any) => {
      currentRow.value = row;
      callback();
    }

    return {
      grid,      
      gridOptions,
      formOptions,
      model,
      initData,
      canCreate,
      itemsTable,
      formSubmit,
      exportExcel,
      isLoading,
      refresh,
      exportReport,
      exportWorksheetA,
      exportWorksheetB,
      exportWorksheetC,
      exportWorksheetD,
      exportWorksheetE,
      exportWorksheetC2,
      exportWorksheetC3,
      endDateFilter,
      startDateFilter,
      onGridEdit,
      updateDetail,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
