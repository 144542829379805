
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, watch } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import SignDataTable from "@/components/process/SignDataTable.vue";

import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import CheckRecordItemsTable from "@/components/document/CheckRecordItemsTable.vue";
import IQCItemsTable from "@/components/document/IQCItemsTable.vue";
import TypeAProductionCheckItemsTable from "@/components/document/TypeAProductionCheckItemsTable.vue";
import WinderItemsTable from "@/components/document/WinderItemsTable.vue";
import RollItemsTable from "@/components/document/RollItemsTable.vue";
import ProductionPackageQCItemsTable from "@/components/document/ProductionPackageQCItemsTable.vue";
import SalesItemsTable from "@/components/document/SalesItemsTable.vue";
import StaffItemsTable from "@/components/document/StaffItemsTable.vue";
import TrackingItemsTable from "@/components/document/TrackingItemsTable.vue";
import FinalQualityInspectionReportItemsTable from "@/components/document/FinalQualityInspectionReportItemsTable.vue";
import HumidityCheckRecordItemsTable from "@/components/document/HumidityCheckRecordItemsTable.vue";
import JobTickeDocumentItemsTable from "@/components/document/JobTickeDocumentItemsTable.vue";
import RemittanceSlipItemsTable from "@/components/document/RemittanceSlipItemsTable.vue";
import PerformanceEvaluationItemsATable from "@/components/document/PerformanceEvaluationItemsATable.vue";
import PerformanceEvaluationItemsBTable from "@/components/document/PerformanceEvaluationItemsBTable.vue";
import EntertainmentItemsTable from "@/components/document/EntertainmentItemsTable.vue";
import TravelItemsTable from "@/components/document/TravelItemsTable.vue";
import SampleNotificationFormItemsTable from "@/components/document/SampleNotificationFormItemsTable.vue";
import CategoryItemTable from "@/components/document/CategoryItemTable.vue";
import IQCTableItemsTable from "@/components/document/IQCTableItemsTable.vue";
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'
import formatDate from 'xe-utils/toDateString';
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";
import SignatureDataTable from "@/components/document-flow/SignatureDataTable.vue";
import BusinessTripDailyReportItemsTable from "@/components/document/BusinessTripDailyReportItemsTable.vue";
import InProgressFlowTable from "@/components/document-flow/InProgressFlowTable.vue";
import DocumentImgFileUpload from "@/components/file/DocumentImgFileUpload.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    SignDataTable,
    SalesItemsTable,
    StaffItemsTable,
    TrackingItemsTable,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    CheckRecordItemsTable,
    IQCItemsTable,
    TypeAProductionCheckItemsTable,
    WinderItemsTable,
    RollItemsTable,
    ProductionPackageQCItemsTable,
    FinalQualityInspectionReportItemsTable,
    HumidityCheckRecordItemsTable,
    JobTickeDocumentItemsTable,
    RemittanceSlipItemsTable,
    PerformanceEvaluationItemsATable,
    PerformanceEvaluationItemsBTable,
    EntertainmentItemsTable,
    TravelItemsTable,
    SampleNotificationFormItemsTable,
    CategoryItemTable,
    IQCTableItemsTable,
    DocumentFileUpload,
    SignatureDataTable,
    BusinessTripDailyReportItemsTable,
    InProgressFlowTable,
    DocumentImgFileUpload,
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref("");
    const readType = ref(false);
    const filereadType = ref(false);
    const needSave = [38, 34, 39];
    const user = CloudFun.current?.user;
    const gridOptions: GridOptions = {
      id: 'document',
      title: '建立文件申請',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: true,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        // { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        // { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentPosition.Name', title: '目前簽核職務', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        {
          title: '文件流程',
          width: '100',
          slots: { default: "flow" }, align: 'center'
        },
        {
          title: '簽核紀錄',
          width: '100',
          slots: { default: "sign" }, align: 'center'
        },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedType.value !== "") {
            params.condition!.and("ContentType", Operator.Equal, selectedType.value);
          }
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          console.log("user.Positions", model.user.Positions);
          //params.condition!.and("CurrentPositionId", Operator.IsNotNull).and("CurrentPositionId", Operator.In, model.user.Positions).and("Status.Value", Operator.NotEqual, 0).and("Status.Value", Operator.NotEqual, 5).and("Status.Value", Operator.NotEqual, 4).and("Status.Value", Operator.NotEqual, 32); //送出狀態 .and("Status.Value", Operator.NotEqual, 0);
          params.condition!.and("CurrentPositionId", Operator.IsNotNull).and("CurrentPositionId", Operator.In, model.user.Positions).and("Status.Value", Operator.NotIn, [0, 4, 5, 32]); //送出狀態 .and("Status.Value", Operator.NotEqual, 0);and("Status.Value", Operator.NotEqual, 0);

          if (user && !user.IsAdmin && !user.isUnitManager && !user.isDepManager && !user.isHR && !user.isDocM && !user.isAcc) {
            params.condition = new Condition("SubmitterId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          if (user && user.userDepartmentId && user.userDepartmentId !== 3 && !user.isHR && !user.isDocM && !user.isAcc && (user.isUnitManager || user.isDepManager)) {
            if (user.isDepManager) {
              console.log('isDepManager', user.userManagerDep);
              console.log(user.userManagerDep);
              //params.condition = new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
              params.condition!.or(new Condition("IsDepManager", Operator.Equal, user.userManagerDep));
              //params.condition = new Condition("Submitter.DepartmentId", Operator.IsNotNull)new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
            }
            if (user.isUnitManager) {
              console.log('isUnitManager');
              params.condition!.or(new Condition("IsUnitManager", Operator.Equal, user.userManagerUnit));
            }
          }
          if (user && user.isHR && (user.isUnitManager || user.isDepManager)) {
            if (user.isDepManager) {
              console.log('isDepManager', user.userManagerDep);
              console.log(user.userManagerDep);
              //params.condition = new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
              params.condition!.or(new Condition("IsDepManager", Operator.Equal, user.userManagerDep));
              //params.condition = new Condition("Submitter.DepartmentId", Operator.IsNotNull)new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
            }
            if (user.isUnitManager) {
              console.log('isUnitManager');
              params.condition!.or(new Condition("IsUnitManager", Operator.Equal, user.userManagerUnit));
            }
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    // const contentTypeChange = (item: any) => {
    //   if (item.data.ContentType >= 0) {
    //     var contentTypeOption = getformOption(item.data.ContentType) as any;
    //     formOptions.items?.push({
    //       field: 'ApplicationDate',
    //       title: '填表(申請)日期',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
    //     })
    //     console.log(form.value)
    //     //Array.prototype.push.apply(formOptions.items, contentTypeOption.items);
    //     //Array.prototype.push.apply(formOptions.rules, contentTypeOption.items);

    //   }
    // }

    const form = ref<any>();
    const rowData = ref<any>({ XmlData:{} }); 

    const defaultFormOptions: VxeFormProps = {
      items: [
        // {
        //   field: 'Title',
        //   title: '名稱',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', readonly: true },
        //     attrs: { type: 'text' }
        //   }
        // },
        // {
        //   field: 'SubmittingTime',
        //   title: '提交時間',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        // },
        // {
        //   field: 'Type',
        //   title: '文件類型',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true, readonly: true }, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // // {
        // //   field: 'ContentType',
        // //   title: '表單類型',
        // //   span: 12,
        // //   itemRender: { name: "$select", props: { disabled: true, readonly: true }, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // // },
        // {
        //   field: 'SubmitterId',
        //   title: '申請人',
        //   span: 12,
        //   slots: { default: "column-submitter" }
        // },
        // {
        //   field: 'CurrentSignatureLevel',
        //   title: '目前簽核層級',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '', type: 'number', disabled: true },
        //   }
        // },
        // {
        //   field: 'Status',
        //   title: '簽核狀態',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = (row: any, callback: any) => {
      row.Status = 0;
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;
      callback();
    }

    const invoice = ref<any>();
    const packingList = ref<any>();
    const onGridEdit = async (row: any, callback: any) => {
     
      SignDocumentRow.value = null
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        console.log("Process-CurrentSignatureLevel:", row.CurrentSignatureLevel);
        console.log("Process-Status:", row.Status);
        console.log("Process-isUnitManager:", row.isUnitManager);
        console.log("Process-isDepManager:", row.isDepManager);
        console.log("CloudFun.current:", CloudFun.current);
        console.log("CloudFun.current?.user.isDepManager:", user);
        console.log("CloudFun.current?.user.isUnitManager:", CloudFun.current?.user.isUnitManager);
        
        var contentTypeOption = getformOption(row.ContentType, model, true, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? row.CurrentSignatureLevel : 0, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? row.CurrentPositionId : 0, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? row.isUnitManager : false, needSave.includes(row.ContentType) && (row.Status == 1 || row.Status == 3) ? user?.isDepManager : false) as any;
        //console.log(contentTypeOption)
        const defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          // console.log(key, contentTypeOption.rules[key])
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;
        //console.log(row)

        if (row.ContentType == 26) {
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (!row.XmlData.Year)
            row.XmlData.Year = year;
          if (!row.XmlData.Month)
            row.XmlData.Month = month;
        }
        if (row.ContentType == 31) {
          invoice.value = row.XmlData.InvoiceId ? await model?.dispatch('invoice/find', row.XmlData.InvoiceId) : null;
          const condition = new Condition("InvoiceNumber", Operator.Equal, row.XmlData.InvoiceNumber);
          var list = row.XmlData.InvoiceNumber ? await model?.dispatch("packingList/query", { condition }) : null;
          packingList.value = list?.[0];
        }
        // if(row.ContentType == 47 || row.ContentType == 49 || row.ContentType == 39) {
        if (fileModal && row.ContentType != 47 && row.ContentType != 48){
          fileModal.show(row);
          console.log('user',user)
          if (user?.isDepManager || user?.isUnitManager) {
            filereadType.value = true;
          }else{
            filereadType.value = false;
          }
        } 
        // }

        if (fileImgModal && (row.ContentType == 47 || row.ContentType == 48)) {
          fileImgModal.show(row);
        }
        if(row.ContentType == 39){
          Object.assign(rowData.value, row);
        }

        // for (const key in row.XmlData) {
        //   formOptions.items?.push({
        //     field: key,
        //     title: key,
        //     span: 12,
        //     itemRender: {
        //       name: '$input',
        //       props: { placeholder: '請輸入文字' },
        //     }
        //   })
        // }
      }
      callback()
    }

    watch(() => [
      rowData.value.XmlData?.Teamwork,
      rowData.value.XmlData?.ServiceAttitude,
      rowData.value.XmlData?.Loyalty,
      rowData.value.XmlData?.Diligence,
      rowData.value.XmlData?.Quality,
      rowData.value.XmlData?.Efficiency,
      rowData.value.XmlData?.Attendance], async(value) => {
        // console.log('in watch');
        // console.log(rowData.value.XmlData)
        if(rowData.value.XmlData) {
          rowData.value.XmlData.TotalScore = parseInt(rowData.value.XmlData.Teamwork) + parseInt(rowData.value.XmlData.ServiceAttitude) + 
            parseInt(rowData.value.XmlData.Loyalty) + parseInt(rowData.value.XmlData.Diligence) + 
            parseInt(rowData.value.XmlData.Quality) + parseInt(rowData.value.XmlData.Efficiency) + parseInt(rowData.value.XmlData?.Attendance);
          if(isNaN(rowData.value.XmlData.TotalScore)) rowData.value.XmlData.TotalScore = 0;
          if(getEvaluationResult()) {
            rowData.value.XmlData.EvaluationResult = '正式任用';
          }else{
            rowData.value.XmlData.EvaluationResult = '不予任用';
          }
        }
    }, { immediate: true });

    const getEvaluationResult = () => {
      console.log('rowData.value.XmlData.Teamwork',rowData.value.XmlData.Teamwork);
      console.log('rowData.value.XmlData.ServiceAttitude',rowData.value.XmlData.ServiceAttitude);
      console.log('rowData.value.XmlData.Loyalty',rowData.value.XmlData.Loyalty);
      console.log('rowData.value.XmlData.Diligence',rowData.value.XmlData.Diligence);
      console.log('rowData.value.XmlData.Efficiency',rowData.value.XmlData.Efficiency);
      console.log('rowData.value.XmlData.Quality',rowData.value.XmlData.Quality);
      console.log('rowData.value.XmlData.TotalScore',rowData.value.XmlData.TotalScore);
      console.log('SS',parseInt(rowData.value.XmlData.Teamwork) > 6 &&
              parseInt(rowData.value.XmlData.ServiceAttitude) > 6 &&
              parseInt(rowData.value.XmlData.Loyalty) > 6 &&
              parseInt(rowData.value.XmlData.Diligence) > 6 &&
              parseInt(rowData.value.XmlData.Efficiency) > 6 &&
              parseInt(rowData.value.XmlData.Quality) > 6 &&
              rowData.value.XmlData.TotalScore >= 80);
      return parseInt(rowData.value.XmlData.Teamwork) > 6 &&
              parseInt(rowData.value.XmlData.ServiceAttitude) > 6 &&
              parseInt(rowData.value.XmlData.Loyalty) > 6 &&
              parseInt(rowData.value.XmlData.Diligence) > 6 &&
              parseInt(rowData.value.XmlData.Efficiency) > 6 &&
              parseInt(rowData.value.XmlData.Quality) > 6 &&
              rowData.value.XmlData.TotalScore >= 80;
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();
    const CheckRecordItemsTable = ref<any>();
    const IQCItemsTable = ref<any>();
    const TypeAProductionCheckItemsTable = ref<any>();
    const WinderItemsTable = ref<any>();
    const RollItemsTable = ref<any>();
    const ProductionPackageQCItemsTable = ref<any>();
    const FinalQualityInspectionReportItemsTable = ref<any>();
    const SalesItemsTable = ref<any>();
    const StaffItemsTable = ref<any>();
    const TrackingItemsTable = ref<any>();
    const HumidityCheckRecordItemsTable = ref<any>();
    const JobTickeDocumentItemsTable = ref<any>();
    const RemittanceSlipItemsTable = ref<any>();
    const PerformanceEvaluationItemsATable = ref<any>();
    const PerformanceEvaluationItemsBTable = ref<any>();
    const EntertainmentItemsTable = ref<any>();
    const TravelItemsTable = ref<any>();
    const SampleNotificationFormItemsTable = ref<any>();
    const BusinessTripDailyReportItemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      if (row.Id) {
        if (row.ContentType == 2) {
          var invoiceQuarterlyItems = itemsTable?.value.getData();
          console.log(invoiceQuarterlyItems)
          if (invoiceQuarterlyItems && invoiceQuarterlyItems.length > 0)
            row.XmlData.InvoiceQuarterlyItems = invoiceQuarterlyItems;
        }
      }
      if (row.ContentType == 34) {
        // const { ManagerEvaluation_Quality, ManagerEvaluation_Interity, ManagerEvaluation_Professionalism, ManagerEvaluation_Progress, ManagerEvaluation_Problem, ManagerEvaluation_Teamwork, ManagerEvaluation_Spontaneity } = row.XmlData
        // const isMissingEvaluation = !ManagerEvaluation_Quality || !ManagerEvaluation_Interity || !ManagerEvaluation_Professionalism || !ManagerEvaluation_Progress || !ManagerEvaluation_Problem || !ManagerEvaluation_Teamwork || !ManagerEvaluation_Spontaneity;
        // const isManagerEvaluationPresent = row.XmlData.ItemsA.every((item: any) => item.ManagerEvaluation !== null);
        // if (!isManagerEvaluationPresent) {
        //   alert('請確認工作目標達成都有進行評核!!');
        // } else if (isMissingEvaluation) {
        //   alert('請確認核心職能項目都有進行評核!!');
        // } else {
        //   submit();
        // }
        submit();
        // if (isMissingEvaluation || !isManagerEvaluationPresent) alert('please complete the Core Competency Evaluation and the Objectives Achievement')
        // else submit();
      } else {
        submit();
      }
    }

    const signTable = ref<any>();
    const SignDocumentRow = ref<any>(null);
    const signDocument = async (row: any, approved: boolean) => {
      if (row.ContentType == 34) {
        const { ManagerEvaluation_Quality, ManagerEvaluation_Interity, ManagerEvaluation_Professionalism, ManagerEvaluation_Progress, ManagerEvaluation_Problem, ManagerEvaluation_Teamwork, ManagerEvaluation_Spontaneity } = row.XmlData
        const isMissingEvaluation = !ManagerEvaluation_Quality || !ManagerEvaluation_Interity || !ManagerEvaluation_Professionalism || !ManagerEvaluation_Progress || !ManagerEvaluation_Problem || !ManagerEvaluation_Teamwork || !ManagerEvaluation_Spontaneity;
        const isManagerEvaluationPresent = row.XmlData.ItemsA.every((item: any) => item.ManagerEvaluation !== null);
        if(approved){
          if (!isManagerEvaluationPresent) {
            alert('請確認工作目標達成都有進行評核!!');
            return false;
          } else if (isMissingEvaluation) {
            alert('請確認核心職能項目都有進行評核!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 2 && !row.XmlData.Overall_Manager){
            alert('請進行部門主管評核!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 2 && (row.XmlData.Next_Manager == null || row.XmlData.Next_Manager == undefined)){
            alert('請輸入進行部門主管意見!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 3 && !row.XmlData.Overall_VicePresident){
            alert('請進行副總經理評核!!');
            return false;
          }
          if(row.CurrentSignatureLevel == 4 && !row.XmlData.Overall_President){
            alert('請進行總經理評核!!');
            return false;
          }
        }
        console.log('row',row);
        console.log('user',user);
      }
      var signData = await signTable.value.submitData();
      var pass = confirm(`確定要${approved ? '核准' : '否決'}此文件嗎?`);
      if (signData && pass) {
        if(row.ContentType == 34 || row.ContentType == 39 || row.ContentType == 38){
          SignDocumentRow.value = { ...row, Approve: approved };
          await form.value.validate((errMap: any) => { if (errMap === undefined) form.value.$emit('submit') })
        }else{
          var result = {
            Type: row.ContentType, //文件內容類型
            DocumentId: row.Id, //文件Id
            Level: row.CurrentSignatureLevel, //當前簽核等級
            SignatoryId: CloudFun.user.Id, //簽核人Id
            Approved: approved, //signData.Approved, //是否通過
            Comment: signData.Comment, //簽核意見
            PositionId: row.CurrentPositionId, //0721 補上簽核階級
          }
        // console.log(result);
          try {
            await model?.dispatch('document/approve', result);
            CloudFun.send("info", {
              subject: "執行成功",
              content: "完成簽核",
            });
            grid.value.isModalPopup = false;
            await grid.value.refresh();
          } catch (e: any) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: e,
            });
          } finally {
            SignDocumentRow.value = null
          }
        }

      }
    }

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    /** 權限開放申請單使用 */
    const requestContentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 物性檢測申請表使用 */
    const physicalPropertiesTestingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 來料檢驗使用 */
    const categorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("iqcCategory/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          params.sortings!.push({ column: 'Ordinal', order: 0 });
          return model!.dispatch("iqcCategory/query", params) // eslint-disable-line
        }
      }
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const exportInvoiceReport = (row: any) => {
      //替換API_URL
      if (invoice.value && invoice.value.Id) {
        window.open(`https://report.imp.cloudfun.app/api/ReportView/InvoiceReportView?invId=${invoice.value.Id}`)
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無法取得資料Id",
        });
      }
    }

    const exportPackingListReport = (row: any) => {
      //替換API_URL
      if (packingList.value && packingList.value.Id) {
        window.open(`https://report.imp.cloudfun.app/api/ReportView/PackingListReportView?packingId=${packingList.value.Id}`)
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無法取得資料Id",
        });
      }
    }

    const problemContentType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const problemContentTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(problemContentType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: problemContentType, totalCount: 1 }));
        },
      },
    };

    const remedialType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const remedialTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(remedialType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: remedialType, totalCount: 1 }));
        },
      },
    };

    const handling = [
      { Id: '1', Name: '要求改善' },
      { Id: '2', Name: '客訴索賠' },
      { Id: '3', Name: '其它' },
    ]
    const handlingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(handling.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: handling, totalCount: 1 }));
        },
      },
    };

    const responsibilityUnit = [
      { Id: '1', Name: '分條' },
      { Id: '2', Name: '覆卷' },
      { Id: '3', Name: '裁切' },
      { Id: '4', Name: '包裝' },
      { Id: '5', Name: '倉管' },
      { Id: '6', Name: '品管' },
      { Id: '7', Name: '供應商' },
      { Id: '8', Name: '業務' },
      { Id: '9', Name: '採購' },
      { Id: '10', Name: '生管' },
      { Id: '11', Name: '其它' },
    ]
    const responsibilityUnitSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(responsibilityUnit.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: responsibilityUnit, totalCount: 1 }));
        },
      },
    };

    const contactDepartment = [
      { Id: '1', Name: 'A.業務部' },
      { Id: '2', Name: 'B.管理部' },
      { Id: '3', Name: 'C.生管組' },
      { Id: '4', Name: 'D.品管組' },
      { Id: '5', Name: 'E.分條組' },
      { Id: '6', Name: 'F.手動分條組' },
      { Id: '7', Name: 'G.切卷組' },
      { Id: '8', Name: 'H.包裝組' },
      { Id: '9', Name: 'I.切管組' },
      { Id: '10', Name: 'J.倉庫組' },
      { Id: '11', Name: 'K.廠務組' },
      { Id: '12', Name: 'L.採購組' },
    ]
    const contactDepartmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(contactDepartment.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: contactDepartment, totalCount: 1 }));
        },
      },
    };

    const messageType = [
      { Id: '1', Name: '一般訊息溝通' },
      { Id: '2', Name: '急件' },
      { Id: '3', Name: '需求' },
      { Id: '4', Name: '通知' },
    ]
    const messageTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(messageType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: messageType, totalCount: 1 }));
        },
      },
    };

    const productSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Color",
          title: "顏色",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          return model!.dispatch("product/query", params)
        }
      }
    };

    const signGrid = ref<any>()
    const signModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition().and("DocumentId", Operator.Equal, signModal.selectedRow.Id)
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = signGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("signatureData/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('signatureData/query') : undefined,
          save: undefined
        }
      } as GridOptions,
      show(row: any) {
        signModal.visible = true;
        signModal.selectedRow = row;
        if (signGrid.value) signGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition("DataMode", Operator.Equal, 0).and("DocumentId", Operator.Equal, flowModal.selectedRow.Id).and("NotifyOnly", Operator
              .Equal, false
            )
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = flowGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("inProgressFlow/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('inProgressFlow/query') : undefined,
          save: model ? (params) => model.dispatch('inProgressFlow/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        flowModal.visible = true;
        flowModal.selectedRow = row;
        if (flowGrid.value) flowGrid.value.refresh();
      }
    })

    /** 權限開放申請單使用 */
    const leavingReasonTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.LeavingReasonType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.LeavingReasonType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const invoiceSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "InvoiceNumber",
      valueField: "Id",
      columns: [
        {
          field: "InvoiceNumber",
          title: "Invoice No",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("invoice/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("invoice/query", params) // eslint-disable-line
        }
      }
    };

    const fileImgGrid = ref<any>()
    const fileImgModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition().and("DocumentId.Value", Operator.Equal, fileImgModal.selectedRow.Id);
            // .and("Type", Operator.Equal, 3).and("Type", Operator.Equal, 2)
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          async insert(formData: FormData, type: any) {
            console.log(type)
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileImgModal.selectedRow.Id, fileType: type });
          },
          async delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileImgModal.selectedRow = row;
        if (fileImgGrid.value) fileImgGrid.value.refresh();
      }
    })

    const foldSetting = ref({
      "PartA": true,
      "PartB": true,
      "PartC": true,
      "ObjectivesAchievement": true,
      "CoreCompetencyEvaluation": true,
      "OverallRating": true,
      "Objectives_Achievement": true,
    })

    const standardOption = ref([
      { value: "E", label: "E" },
      { value: "D", label: "D" },
      { value: "G", label: "G" },
      { value: "I", label: "I" },
      { value: "C", label: "C" }
    ])

    const fold = (target: string) => {
      (foldSetting.value as any)[target] = !(foldSetting.value as any)[target];
    };

    const onSaveSuccess = async () => {
      let signData = await signTable.value.submitData();
      if (SignDocumentRow.value) {
        let result = {
          Type: SignDocumentRow.value.ContentType, //文件內容類型
          DocumentId: SignDocumentRow.value.Id, //文件Id
          Level: SignDocumentRow.value.CurrentSignatureLevel, //當前簽核等級
          SignatoryId: CloudFun.user.Id, //簽核人Id
          Approved: SignDocumentRow.value.Approve, //signData.Approved, //是否通過
          Comment: signData.Comment, //簽核意見
          PositionId: SignDocumentRow.value.CurrentPositionId, //0721 補上簽核階級
        }
        try {
          await model?.dispatch('document/approve', result);
          CloudFun.send("info", {
            subject: "執行成功",
            content: "完成簽核",
          });
          grid.value.isModalPopup = false;
          await grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        } finally {
          SignDocumentRow.value = null
        }
      }
    }

    return {
      grid,
      form,
      gridOptions,
      formOptions,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      signDocument,
      signTable,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      CheckRecordItemsTable,
      IQCItemsTable,
      TypeAProductionCheckItemsTable,
      WinderItemsTable,
      RollItemsTable,
      ProductionPackageQCItemsTable,
      FinalQualityInspectionReportItemsTable,
      SalesItemsTable,
      TrackingItemsTable,
      StaffItemsTable,
      HumidityCheckRecordItemsTable,
      JobTickeDocumentItemsTable,
      RemittanceSlipItemsTable,
      jobTickeSelectorOptions,
      customerSelectorOptions,
      requestContentSelectorOptions,
      PerformanceEvaluationItemsATable,
      PerformanceEvaluationItemsBTable,
      EntertainmentItemsTable,
      TravelItemsTable,
      physicalPropertiesTestingSelectorOptions,
      SampleNotificationFormItemsTable,
      categorySelectorOptions,
      invoice,
      packingList,
      formatDate,
      formatAmount,
      fileModal,
      fileGrid,
      exportInvoiceReport,
      exportPackingListReport,
      remedialTypeSelectorOptions,
      problemContentTypeSelectorOptions,
      handlingSelectorOptions,
      responsibilityUnitSelectorOptions,
      contactDepartmentSelectorOptions,
      messageTypeSelectorOptions,
      productSelectorOptions,
      signGrid,
      signModal,
      BusinessTripDailyReportItemsTable,
      flowGrid,
      flowModal,
      leavingReasonTypeSelectorOptions,
      needSave,
      invoiceSelectorOptions,
      fileImgGrid,
      fileImgModal,
      standardOption,
      foldSetting,
      fold,
      onSaveSuccess,
      SignDocumentRow,
      filereadType,
      readType,
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
