/* eslint-disable */
import { VxeFormProps } from 'vxe-table'
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive } from '@cloudfun/core'

let model: any = null;

export function getformOption(contentType: any, CloudFunModel: any, readonly = false, currentLevel = 0, currentPosition = 0, isUnitManager = false, isDepManager = false) {
    console.log('contentType:',contentType);
    console.log('getformOption.currentPosition',currentPosition);
    console.log('getformOption.isUnitManager',isUnitManager);
    console.log('getformOption.isDepManager',isDepManager);
    console.log('getformOption.currentLevel',currentLevel);
    console.log("getformOption user:", CloudFun.current?.user);
    let option = null;
    model = CloudFunModel; 
    switch (contentType) {
        case 0: // 出差申請
            option = getBusinessTripApplication(readonly);
            break;
        case 1: // 用量異動項目
            option = getBOMUsageQuantityModifyItem(readonly);
            break;
        case 2: // 請購單(國內)
            option = getDomesticInvoiceQuarterly(readonly);
            break;
        case 3: // 報修單
            option = getFailureReport(readonly);
            break;
        case 4: // 請購單(國外)
            option = getForeignInvoiceQuarterly(readonly);
            break;
        case 5: //資訊需求申請表
            option = getITRequirementApplication(readonly);
            break;
        case 6: //取替代料件異動申請表
            option = getMaterialSubstitutesApplication(readonly);
            break;
        case 7: // 雜費申請單
            option = getMiscellaneousChargeApplication(readonly);
            break;
        case 8: // 公司大小印章用印申請
            option = getOfficialSealApplication(readonly);
            break; 
        case 9: // 付款申請
            option = getPaymentApplication(readonly);
            break;
        case 10: // 人員考核月報表
            option = getPersonnelAssessmentMonthly(readonly);
            break;    
        case 11: // 品號異動申請單
            option = getProductNumberModifuApplication(readonly);
            break;
        case 12: // 請款單
            option = getPurchaseRequisition(readonly);
            break;
        case 13: // 報價單
            option = getQuotation(readonly);
            break;
        case 14: // 客戶訂單
            option = getOrder(readonly);
            break;
        case 15: // 客戶超限放行單
            option = getCustomerCreditApplication(readonly);
            break;
        case 16: // 巡檢作業
            option = getCheckRecord(readonly);
            break;
        case 17: // 來料檢驗(標籤)
            option = getIQC_Tag(readonly);
            break;
        case 18: // 來料檢驗(彩盒)
            option = getIQC_ColorBox(readonly);
            break;
        case 19: // 來料檢驗(紙箱)
            option = getIQC_Carton(readonly);
            break;
        case 20: // 來料檢驗(紙卡)
            option = getIQC_PaperCard(readonly);
            break;
        case 21: // 生產規格首件QC檢驗紀錄表(分條) 
            option = getTypeAProductionCheck(readonly);
            break;
        case 22: // 生產規格首件QC檢驗紀錄表(複卷、切卷) 
            option = getTypeBProductionCheckA(readonly);
            break;
        case 23: // 生產現場包裝首件QC檢查表
            option = getProductionPackageQC(readonly);
            break;
        case 24: // 產品最終品質檢驗報告
            option = getFinalQualityInspectionReport(readonly);
            break;
        case 25: // 樣品製作樣品通知單
            option = getSampleNotice(readonly);
            break;
        case 26: // 紙管濕度檢驗紀錄表
            option = getHumidityCheckRecord(readonly);
            break;
        case 30: // 工單
            option = getJobTickeDocument(readonly);
            break;
        case 31: // 進款單
            option = getRemittanceSlip(readonly);
            break;
        case 32: // MES權限開放申請單
            option = getPermissionRequest(readonly);
            break;
        case 33: // ERP權限開放申請單
            option = getPermissionRequest(readonly);
            break;
        case 34: // 年終績效評估
            option = getPerformanceEvaluation(readonly, currentLevel, currentPosition, isUnitManager);
            break;
        case 35: // 差旅與交際費用申請單
            option = getTravelEntertainmentReport(readonly);
            break;
        case 36: // 人員增補申請單
            option = getPersonnelSupplementary(readonly);
            break;
        case 37: // 人事及薪資異動單
            option = getPersonnelandPayrollChangeRequest(readonly);
            break;
        case 38: // 離職申請與移交確認紀錄
            option = getResignationandHandoverecord(readonly, currentLevel);
            break;
        case 39: // 員工試用期滿考核表
            option = getEmployeeProbationAssessment(readonly, currentLevel, currentPosition, isUnitManager, isDepManager);
            break;
        case 40: // 物性檢測申請表
            option = getPhysicalPropertiesTesting(readonly);
            break;
        case 41: // 檢驗紀錄表
            option = getTestingReport(readonly);
            break;
        case 42: // 出差編號填寫表
            option = getBusinessTripNumberList(readonly);
            break;
        case 43: // 製作樣品通知單
            option = getSampleNotificationForm(readonly);
            break;
        case 44: // 銷貨折讓原因單
            option = getSalesAllowance(readonly);
            break;
        case 45: // 生產規格首件QC檢驗紀錄表(切卷) 
            option = getTypeBProductionCheckB(readonly);
            break;
        case 46: // 來料檢驗 
            option = getIQCTable(readonly);
            break;
        case 47: // 品質異常處理單
            option = getQualityHandlingForm(readonly);
            break;
        case 48: // 品質異常原因調查表
            option = getQualityAbnormalityCauseForm(readonly);
            break;
        case 49: // 信息聯絡處理單
            option = getInformationContactForm(readonly);
            break;
        case 50: // 出差工作日報表
            option = getBusinessTripDailyReport(readonly);
            break;
        case 51: // 銷貨折讓原因單(內銷)
            option = getDomesticSalesAllowance(readonly);
            break;
        case 52: // 人事異動單
            option = getPersonnelChangeRequest(readonly);
            break;
        case 53: // 人事異動單
            option = getEmployeeChangeRequest(readonly);
            break;
    }
    return option;
}

// 出差申請 0
function getBusinessTripApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '申請人姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },            
            {
                field: 'XmlData.AgentNumber',
                title: '代理人編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AgentName',
                title: '代理人姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AgentDepartmentId',
                title: '代理人部門',
                span: 12,
                slots: { default: "column-agent-department" }
            },
            {
                field: 'XmlData.StartDate',
                title: '預計開始日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.EndDate',
                title: '預計結束日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Destination',
                title: '地點',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Days',
                title: '天數',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Traffic',
                title: '交通需求',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Currency',
                title: '預支幣別',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PrepaidTravelAllowance',
                title: '預支費用',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },            
            {
                field: 'XmlData.Type',
                title: '出差類型',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.BusinessTripType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.Description',
                title: '事由',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.TicketDescription',
                title: '訂票說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.TravelStroke',
                title: '行程說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//BOM用量異動項目 1
function getBOMUsageQuantityModifyItem(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ProductModifyTpye',
                title: '產品申請類別',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.ModifyType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.ProductMainMaterialNumber',
                title: '產品主件品號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductMainMaterialName',
                title: '產品主件品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductMainMaterialSpecification',
                title: '產品主件規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductMainMaterialUnit',
                title: '產品主件單位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductStandardBatch',
                title: '產品標準批量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.ProductDescription',
                title: '產品修改說明',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.ProductRemark',
                title: '產品備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '產品製令單別',
                span: 24,
                slots: { default: 'column-ProductWrokOrderItems' }
            },
            {
                title: '產品異動項目',
                span: 24,
                slots: { default: 'column-ProductModifyItems' }
            },
            //成品
            {
                field: 'XmlData.FinishedProductModifyTpye',
                title: '成品申請類別',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.ModifyType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.FinishedProductMainMaterialNumber',
                title: '成品主件品號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.FinishedProductMainMaterialName',
                title: '成品主件品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.FinishedProductMainMaterialSpecification',
                title: '成品主件規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.FinishedProductMainMaterialUnit',
                title: '成品主件單位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.FinishedProductStandardBatch',
                title: '成品批量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.FinishedProductDescription',
                title: '成品修改說明',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.FinishedProductRemark',
                title: '成品備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '成品製令單別',
                span: 24,
                slots: { default: 'column-FinishedProductWrokOrderItems' }
            },
            {
                title: '成品異動項目',
                span: 24,
                slots: { default: 'column-FinishedProductModifyItems' }
            },
            //覆卷
            {
                field: 'XmlData.ReRollModifyTpye',
                title: '覆卷申請類別',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.ModifyType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.ReRollMainMaterialNumber',
                title: '覆卷主件品號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReRollMainMaterialName',
                title: '覆卷主件品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReRollMainMaterialSpecification',
                title: '覆卷主件規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReRollMainMaterialUnit',
                title: '覆卷主件單位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReRollDescription',
                title: '覆卷批量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.FinishedProductDescription',
                title: '覆卷修改說明',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.ReRollRemark',
                title: '覆卷備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '覆卷製令單別',
                span: 24,
                slots: { default: 'column-ReRollWrokOrderItems' }
            },
            {
                title: '覆卷異動項目',
                span: 24,
                slots: { default: 'column-ReRollModifyItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//請款單(國內)資料 2
function getDomesticInvoiceQuarterly(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [            
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: readonly } }
            },
            {
                field: 'XmlData.PurchaseBillNo',
                title: '進貨單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PurchaseOrderNo',
                title: '採購號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.SupplierNumber',
                title: '供應廠商編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.SupplierName',
                title: '供應廠商名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Delivery',
                title: '交貨項目',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Currency',
                title: '幣別',
                span: 12,
                //itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
                itemRender: { name: '$select', props: { type: 'number', readonly: readonly }, options: [
                    { value: 0, label: "台幣" },
                    { value: 1, label: "美金" },
                    { value: 2, label: "日幣" },
                    { value: 3, label: "人民幣" },
                    { value: 4, label: "歐元" }
                ]}
            },
            {
                field: 'XmlData.Amount',
                title: '交貨金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.DeliveryDate',
                title: '交貨日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: readonly } }
            },
            {
                field: 'XmlData.PaymentDate',
                title: '付款日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: readonly } }
            },
            {
                field: 'XmlData.PaymentType',
                title: '付款方式',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.PaymentType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.InvoiceNo',
                title: '發票號碼',
                span: 12,
                itemRender: { name: '$input', props: { readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.Description',
                title: '交易條件',
                span: 12,
                itemRender: { name: '$input', props: { readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.PurchaseQuantity',
                title: '進貨數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-items' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
          }
    }
    return forms;
}

//報修單 3
function getFailureReport(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '申請人姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },           
            {
                field: 'XmlData.ReportTime',
                title: '報修時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Localtion',
                title: '維修地點',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Platform',
                title: '維修機台',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.FixItemName',
                title: '維修項目名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Reason',
                title: '故障原因',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//請款單(國外)資料 4
function getForeignInvoiceQuarterly(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [            
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: readonly } }
            },
            {
                field: 'XmlData.PurchaseBillNo',
                title: '進貨單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PurchaseOrderNo',
                title: '採購號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.SupplierNumber',
                title: '供應廠商編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.SupplierName',
                title: '供應廠商名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Delivery',
                title: '交貨項目',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Currency',
                title: '幣別',
                span: 12,
                itemRender: { name: '$select', props: { type: 'number', readonly: readonly }, options: [
                    { value: 0, label: "台幣" },
                    { value: 1, label: "美金" },
                    { value: 2, label: "日幣" },
                    { value: 3, label: "人民幣" },
                    { value: 4, label: "歐元" }
                ]}
            },
            {
                field: 'XmlData.Amount',
                title: '交貨金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.DeliveryDate',
                title: '交貨日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: readonly } }
            },
            {
                field: 'XmlData.PaymentDate',
                title: '付款日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: readonly } }
            },
            {
                field: 'XmlData.PaymentType',
                title: '付款方式',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.PaymentType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.InvoiceNo',
                title: '發票號碼',
                span: 12,
                itemRender: { name: '$input', props: { readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.Description',
                title: '交易條件',
                span: 12,
                itemRender: { name: '$input', props: { readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.PurchaseQuantity',
                title: '進貨數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-items' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
          }
    }
    return forms;
}

//資訊需求申請表 5
function getITRequirementApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [            
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Description',
                title: '申請說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OtherItemDescription',
                title: '其它項目說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '申請類別',
                span: 24,
                slots: { default: 'column-types' }
            },
            {
                title: '事由及原因',
                span: 24,
                slots: { default: 'column-reasons' }
            },
            {
                title: '附件',
                span: 24,
                slots: { default: 'column-annex' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//取替代料件異動申請表 6
function getMaterialSubstitutesApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '申請人姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Type',
                title: '申請類別',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.ModifyType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },            
            {
                field: 'XmlData.MaterialNumber',
                title: '元件品號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MaterialName',
                title: '品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MaterialSpecification',
                title: '元件規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MaterialUnit',
                title: '元件單位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MainMaterialNumber',
                title: '主件品號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MainMaterialName',
                title: '主件品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MainMaterialSpecification',
                title: '主件規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MainMaterialUnit',
                title: '主件單位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReplaceType',
                title: '取/替代',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.ReplaceType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.Description',
                title: '修改說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            // {
            //     field: 'XmlData.Remark',
            //     title: '備註',
            //     span: 24,
            //     itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            // },
            {
                title: '取/替代項目',
                span: 24,
                slots: { default: 'column-MaterialSubstitutesItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

// 雜費申請單 7
function getMiscellaneousChargeApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.SupplierName',
                title: '廠商名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Amount',
                title: '合計金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Description',
                title: '雜費申請事由',
                span: 24,
                itemRender: { name: '$textarea', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
            'XmlData.ApplicationDate': [{ required: true }],
          }
    }
    return forms;
}

//公司大小印章用印申請 8
function getOfficialSealApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.EmployeeName',
                title: '申請人姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },   
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Destination',
                title: '申請目的',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.RequiredDate',
                title: '需求日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Requirement',
                title: '需求內容',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.Destination': [{ required: true }],
            'XmlData.Requirement': [{ required: true }],
            'XmlData.RequiredDate': [{ required: true }],
            'XmlData.EmployeeName': [{ required: true }],
            'XmlData.DepartmentId': [{ required: true }],
          }
    }
    return forms;
}

//付款申請 9
function getPaymentApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },            
            {
                field: 'XmlData.RequirementDate',
                title: '需求日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.CustomerName',
                title: '客戶名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProjectNumber',
                title: '案號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                title: '申請類別',
                span: 12,
                slots: { default: 'column-ApplicationTypes' }
            },
            {
                field: 'XmlData.PaymentType',
                title: '付款方式',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.PaymentType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.Currency',
                title: '幣別',
                span: 12,
                itemRender: { name: '$select', props: { type: 'number', readonly: readonly }, options: [
                    { value: 0, label: "台幣" },
                    { value: 1, label: "美金" },
                    { value: 2, label: "日幣" },
                    { value: 3, label: "人民幣" },
                    { value: 4, label: "歐元" }
                ]}
            },
            {
                field: 'XmlData.Amount',
                title: '需求金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Requirement',
                title: '需求內容',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//人員考核月報表 10
function getPersonnelAssessmentMonthly(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },            
            {
                field: 'XmlData.AssessmentDate',
                title: '考核日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.StartDate',
                title: '到職日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.DeliveryDate',
                title: '交貨日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.PaymentDate',
                title: '付款日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.SupervisorOpinion',
                title: '主管意見',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.InvoiceNo',
                title: '發票號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Description',
                title: '交易條件',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PaymentType',
                title: '付款方式',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.PaymentType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.TotalScore',
                title: '得分總計',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.PurchaseQuantity',
                title: '進貨數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.AssessmentRemark',
                title: '考核備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-items' }
            },
            {
                title: '考核項目',
                span: 24,
                slots: { default: 'column-AssessmentItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//品號異動申請單 11
function getProductNumberModifuApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            // {
            //     field: 'XmlData.EmployeeNo',
            //     title: '員工編號',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.EmployeeName',
            //     title: '員工姓名',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },            
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ModifyNamereasons',
                title: '異動原因說明',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ModifyType',
                title: '申請類別',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.ModifyType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.ModifyNameSpecification',
                title: '修改品名規格',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            {
                field: 'XmlData.IsStock',
                title: '庫存管理',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            {
                title: '取/替代項目',
                span: 24,
                slots: { default: 'column-ProductNumberModifuItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//請購單 12
function getPurchaseRequisition(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.SupplierName',
                title: '廠商',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },               
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ObjectDescription',
                title: '用途',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                title: '請購類別',
                span: 24,
                slots: { default: 'column-PurchaseRequisitionType' }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-PurchaseRequisitionItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeNo': [{ required: true }],
          }
    }
    return forms;
}

//報價單 13
function getQuotation(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.CustomerNo',
                title: '客戶編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.CustomerName',
                title: '客戶名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Correspondent',
                title: '聯絡人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Phone',
                title: '電話號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Fax',
                title: '傳真',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.QuotationNo',
                title: '報價單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },              
            {
                field: 'XmlData.QuotationDate',
                title: '報價日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.PaymentType',
                title: '付款方式',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.PaymentType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.ObjectDescription',
                title: '用途',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                title: '報價類別',
                span: 24,
                slots: { default: 'column-PurchaseRequisitionType' }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-PurchaseRequisitionItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.CustomerNo': [{ required: true }],
            'XmlData.CustomerName': [{ required: true }],
            'XmlData.Phone': [
                {
                  type: "string",
                  pattern: new RegExp(
                    "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
                  ),
                },
              ],
          }
    }
    return forms;
}

//客戶訂單 14
function getOrder(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            // {
            //     field: 'XmlData.CustomerName',
            //     title: '客戶名稱',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.OrderNumber',
                title: '訂單編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },            
            {
                field: 'XmlData.Correspondent',
                title: '聯絡人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OrderDate',
                title: '訂單日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },            
            {
                field: 'XmlData.PaymentDescription',
                title: '付款條件',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TaxType',
                title: '課稅別',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Phone',
                title: '電話號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Fax',
                title: '傳真',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },  
            {
                field: 'XmlData.CustomerOrderNumber',
                title: '客戶單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Sales',
                title: '業務',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Address',
                title: '送貨地址',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OrderStaff',
                title: '接單人員',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },                      
            {
                field: 'XmlData.Quantity',
                title: '數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Tax',
                title: '稅額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Amount',
                title: '金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.TotalAmount',
                title: '總金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-OrderItems' }
            },
            // {
            //     field: 'XmlData.Remark',
            //     title: '備註',
            //     span: 24,
            //     itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            // },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.CustomerName': [{ required: true }],
            'XmlData.OrderNumber': [{ required: true }],
            'XmlData.Phone': [
                {
                  type: "string",
                  pattern: new RegExp(
                    "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
                  ),
                },
              ],
          }
    }
    return forms;
}

//客戶超限放行單 15
function getCustomerCreditApplication(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            // {
            //     field: 'XmlData.CustomerCode',
            //     title: '客戶代號',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.CustomerShortName',
            //     title: '客戶簡稱',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },            
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.CreditFacilities',
                title: '信用額度',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Description',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OrderBacklog',
                title: '未交貨',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Unpaid',
                title: '未付款',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Excess',
                title: '共超額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-CustomerCreditItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.CustomerCode': [{ required: true }],
            'XmlData.CustomerShortName': [{ required: true }],
          }
    }
    return forms;
}

//巡檢作業 16
function getCheckRecord(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            // {
            //     field: 'XmlData.CheckRecordName',
            //     title: '巡檢人員',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.Time',
                title: '巡檢日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-CheckRecordItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.CheckRecordName': [{ required: true }],
            'XmlData.Time': [{ required: true }],
          }
    }
    return forms;
}

//來料檢驗基礎檔案
function getIQC(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Category',
                title: '類別',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.IncomingTime',
                title: '來料日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.TestTime',
                title: '檢驗日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-IQCItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.CheckRecordName': [{ required: true }],
            'XmlData.Time': [{ required: true }],
          }
    }
    return forms;
}

//來料檢驗(標籤) 17
function getIQC_Tag(readonly: boolean) {
    var forms = getIQC(readonly);
    return forms;
}

//來料檢驗(彩盒) 18
function getIQC_ColorBox(readonly: boolean) {
    var forms = getIQC(readonly);
    return forms;
}

//來料檢驗(紙箱) 19
function getIQC_Carton(readonly: boolean) {
    var forms = getIQC(readonly);
    return forms;
}

//來料檢驗(紙卡) 20
function getIQC_PaperCard(readonly: boolean) {
    var forms = getIQC(readonly);
    return forms;
}

//生產規格首件QC檢驗紀錄表(分條) 21
function getTypeAProductionCheck(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Manager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            // {
            //     field: 'XmlData.Time',
            //     title: '日期',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            // },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '品項資料',
                span: 24,
                slots: { default: 'column-TypeAProductionCheckItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Manager': [{ required: true }],
            'XmlData.Time': [{ required: true }],
          }
    }
    return forms;
}

//生產規格首件QC檢驗紀錄表(複卷) 22
function getTypeBProductionCheckA(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Manager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '複卷資料',
                span: 24,
                slots: { default: 'column-WinderItems' }
            },
            // {
            //     title: '切卷資料',
            //     span: 24,
            //     slots: { default: 'column-RollItems' }
            // },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Manager': [{ required: true }],
          }
    }
    return forms;
}

//生產現場包裝首件QC檢查表 23
function getProductionPackageQC(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Manager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            // {
            //     field: 'XmlData.Code',
            //     title: '客戶代碼',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.CustomerId',
                title: '客戶',
                span: 12,
                slots: { default: "column-Customer" }
            },
            // {
            //     field: 'XmlData.JobTickeId',
            //     title: '工單單號',
            //     span: 12,
            //     slots: { default: "column-JobTicke" }
            // },
            // {
            //     field: 'XmlData.Number',
            //     title: '客訂單號',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-ProductionPackageQCItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Manager': [{ required: true }],
          }
    }
    return forms;
}

//產品最終品質檢驗報告 24
function getFinalQualityInspectionReport(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            // {
            //     field: 'XmlData.TestName',
            //     title: '測試人員',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.TestTime',
            //     title: '檢驗日期',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            // },
            {
                field: 'XmlData.CustomerId',
                title: '客戶',
                span: 12,
                slots: { default: "column-Customer" }
            },            
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-FinalQualityInspectionReportItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

//樣品製作樣品通知單 25
function getSampleNotice(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                title: '業務端資料',
                span: 24,
                slots: { default: 'column-SalesItems' }
            },
            {
                title: '樣品製作人員資料',
                span: 24,
                slots: { default: 'column-StaffItems' }
            },
            {
                title: '樣品製作人員資料(追蹤表)',
                span: 24,
                slots: { default: 'column-TrackingItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

//紙管濕度檢驗紀錄表 26
function getHumidityCheckRecord(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Manager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Year',
                title: '年份',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Month',
                title: '月份',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                    { value: 6, label: 6 },
                    { value: 7, label: 7 },
                    { value: 8, label: 8 },
                    { value: 9, label: 9 },
                    { value: 10, label: 10 },
                    { value: 11, label: 11 },
                    { value: 12, label: 12 },
                ]}
            },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-HumidityCheckRecordItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Manager': [{ required: true }],
          }
    }
    return forms;
}

//工單 30
function getJobTickeDocument(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.JobTickeNo',
                title: '工號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OrderDate',
                title: '接單日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.CustomerNumber',
                title: '客戶代號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.CustomerTaxType',
                title: '客戶稅別',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OrderNumber',
                title: '訂單號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ContainerType',
                title: '櫃型',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DeliveryDate',
                title: '工廠交貨日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.CustomerDeliveryDate',
                title: '客戶工廠交貨日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.TotleRadixQuantity',
                title: '箱/卷數',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.TotleOrderQuantity',
                title: '總下單件數',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.TotleQuantity',
                title: '總數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.TotalWeight',
                title: '總重量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Totle',
                title: '總計',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Status',
                title: '狀態',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.JobTickeStatus).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-JobTickeDocumentItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Manager': [{ required: true }],
          }
    }
    return forms;
}

//進款單 31
function getRemittanceSlip(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.JobTickeId',
                title: '工單',
                span: 12,
                slots: { default: "column-JobTicke" }
            },
            {
                field: 'XmlData.InvoiceNumber',
                title: '發票號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.CustomerId',
                title: '客戶',
                span: 12,
                slots: { default: "column-Customer" }
            },
            {
                field: 'XmlData.Currency',
                title: '幣別',
                span: 12,
                itemRender: { name: '$select', props: { type: 'number', readonly: readonly }, options: [
                    { value: 0, label: "台幣" },
                    { value: 1, label: "美金" },
                    { value: 2, label: "日幣" },
                    { value: 3, label: "人民幣" },
                    { value: 4, label: "歐元" }
                ]}
            },
            {
                field: 'XmlData.Amount',
                title: '出貨金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Tax',
                title: '稅額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: false } }
            },
            {
                field: 'XmlData.TotleAmount',
                title: '總金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: false } }
            },
            {
                field: 'XmlData.Commission',
                title: '佣金',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.DeliveryDate',
                title: '我方交貨日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Description',
                title: '交易條件',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.RemittanceDate',
                title: '進款日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },  
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },          
            {
                field: 'XmlData.Quantity',
                title: '出貨數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-RemittanceSlipItems' }
            },
            {
                title: '發票資料',
                span: 24,
                slots: { default: 'column-invoice' }
            },
            {
                title: '包裝資料',
                span: 24,
                slots: { default: 'column-packinglist' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 32 33 權限開放申請單
function getPermissionRequest(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            // {
            //     field: 'XmlData.DepartmentName',
            //     title: '申請單位',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            // {
            //     field: 'XmlData.EmployeeName',
            //     title: '申請者',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.SystemName',
                title: '系統名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                title: '權限開放程度',
                span: 24,
                slots: { default: 'column-requestContent' }
            },
            {
                field: 'XmlData.Remark',
                title: '申請單位主要需求說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EmployeeName': [{ required: true }],
            'XmlData.Remark': [{ required: true }],
          }
    }
    return forms;
}

// 34 年終績效評估
function getPerformanceEvaluation(readonly: boolean, currentLevel: number, currentPosition: number, isUnitManager: boolean) {
    var forms: VxeFormProps = {        
        items: [
            {
                field: 'XmlData.Annual',
                title: '年度(Annual)',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true } }
            },
            {
                span: 24,
                slots: { default: 'directions01' }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名(Name of Employee)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true } }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號(Employee No.)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門(部/課/組)(Dept.)',
                span: 12,
                titleWidth: 210,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Shift',
                title: '班別(Shift)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true } }
            },
            {
                field: 'XmlData.Position',
                title: '目前職稱(Current Position)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true } }
            },
            {
                field: 'XmlData.Seniority',
                title: '年資(Seniority in Position)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', readonly: true } }
            },
            {
                field: 'XmlData.Manager',
                title: '班長/組長或單位主管(Team Leader/Immediate Manager)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '填表日期(Date)',
                span: 12,
                titleWidth: 210,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: true, placeholder: '請輸入日期' } }
            }, 
            {
                span: 24,
                slots: { default: 'partA' }
            },
            // {
            //     span: 24,
            //     slots: { default: 'partATitle' }
            // },            
            // {
            //     span: 24,
            //     slots: { default: 'directions02' }
            // },
            // {
            //     //title: 'A.工作目標達成',
            //     span: 24,
            //     slots: { default: 'column-PerformanceEvaluationItemsA' }
            // },
            // {
            //     span: 24,
            //     slots: { default: 'directions03' }
            // },
            // {
            //     //title: 'B.工作目標達成',
            //     span: 24,
            //     slots: { default: 'column-PerformanceEvaluationItemsB' }
            // },            
            // {
            //     span: 24,
            //     slots: { default: 'partBTitle' }
            // }, 
            // {
            //     span: 24,
            //     slots: { default: 'column-Quality' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Quality',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: readonly } }
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Quality',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-Interity' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Interity',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Interity',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-Professionalism' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Professionalism',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Professionalism',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-Progress' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Progress',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Progress',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-Problem' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Problem',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Problem',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 1 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-Teamwork' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Teamwork',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Teamwork',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 1 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-Spontaneity' }
            // },
            // {
            //     field: 'XmlData.SelfEvaluation_Spontaneity',
            //     title: '員工自評 Employee',
            //     span: 12,
            //     itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            // {
            //     field: 'XmlData.ManagerEvaluation_Spontaneity',
            //     title: '班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager',
            //     span: 12,
            //     // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            //     itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 1 ? false : true }, options: [
            //         { value: "E", label: "E" },
            //         { value: "D", label: "D" },
            //         { value: "G", label: "G" },
            //         { value: "I", label: "I" },
            //         { value: "C", label: "C"  }
            //     ]}
            // },
            {
                span: 24,
                slots: { default: 'partB' }
            },
            {
                span: 24,
                slots: { default: 'column-Overall' }
            },
            {
                field: 'XmlData.Overall_Employee',
                title: '員工自評 Employee',
                span: 24,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "E", label: "E" },
                    { value: "D", label: "D" },
                    { value: "G", label: "G" },
                    { value: "I", label: "I" },
                    { value: "C", label: "C"  }
                ]}
            },
            {
                field: 'XmlData.Overall_UnitManager',
                title: '單位主管評核 Rating by Department Manager',
                span: 12,
                // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true,required:currentLevel != 0 && currentLevel == 2 ? false : true } }
                itemRender: { name: '$select', props: {  placeholder: '核准後會由系統計算', readonly: true, disabled: true, required:currentLevel != 0 && currentLevel == 1 ? false : true }, options: [
                    { value: "E-", label: "E-" },
                    { value: "E", label: "E" },
                    { value: "E+", label: "E+" },
                    { value: "D-", label: "D-" },
                    { value: "D", label: "D" },
                    { value: "D+", label: "D+" },
                    { value: "G-", label: "G-" },
                    { value: "G", label: "G" },
                    { value: "G+", label: "G+" },
                    { value: "I-", label: "I-" },
                    { value: "I", label: "I" },
                    { value: "I+", label: "I+" },
                    { value: "C-", label: "C-" },
                    { value: "C", label: "C" },
                    { value: "C+", label: "C+" }
                ]}
            },
            {
                field: 'XmlData.Overall_Manager',
                title: '部門主管評核 Rating by Department Manager',
                span: 12,
                // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 2 ? false : true,required:currentLevel != 0 && currentLevel == 2 ? false : true } }
                itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 2 && !isUnitManager ? false : true, disabled: currentLevel != 0 && currentLevel == 2 && !isUnitManager ? false : true, required:true }, options: [
                    { value: "E-", label: "E-" },
                    { value: "E", label: "E" },
                    { value: "E+", label: "E+" },
                    { value: "D-", label: "D-" },
                    { value: "D", label: "D" },
                    { value: "D+", label: "D+" },
                    { value: "G-", label: "G-" },
                    { value: "G", label: "G" },
                    { value: "G+", label: "G+" },
                    { value: "I-", label: "I-" },
                    { value: "I", label: "I" },
                    { value: "I+", label: "I+" },
                    { value: "C-", label: "C-" },
                    { value: "C", label: "C" },
                    { value: "C+", label: "C+" }
                ]}
            },
            // {
            //     field: 'XmlData.Overall_ManagerValue',
            //     title: '部門主管評核分數 the President',
            //     span: 12,
            //     itemRender: { name: '$input', props: {type: 'float', placeholder: '請輸入額外評分', readonly: currentLevel != 0 && currentLevel == 2 ? false : true,required:currentLevel != 0 && currentLevel == 2 ? false : true } }
            // },
            {
                field: 'XmlData.Overall_VicePresident',
                title: '副總經理評核 the President',
                span: 12,
                // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 3 ? false : true,required:currentLevel != 0 && currentLevel == 3 ? false : true } }
                itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 3 ? false : true, disabled: currentLevel != 0 && currentLevel == 3 ? false : true, required:currentLevel != 0 && currentLevel == 3 ? false : true }, options: [
                    { value: "E-", label: "E-" },
                    { value: "E", label: "E" },
                    { value: "E+", label: "E+" },
                    { value: "D-", label: "D-" },
                    { value: "D", label: "D" },
                    { value: "D+", label: "D+" },
                    { value: "G-", label: "G-" },
                    { value: "G", label: "G" },
                    { value: "G+", label: "G+" },
                    { value: "I-", label: "I-" },
                    { value: "I", label: "I" },
                    { value: "I+", label: "I+" },
                    { value: "C-", label: "C-" },
                    { value: "C", label: "C" },
                    { value: "C+", label: "C+" }
                ]}
            },
            // {
            //     field: 'XmlData.Overall_VicePresidentValue',
            //     title: '副總經理評核分數 the President',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入額外評分', readonly: currentLevel != 0 && currentLevel == 3 ? false : true,required:currentLevel != 0 && currentLevel == 3 ? false : true } }
            // },
            {
                field: 'XmlData.Overall_President',
                title: '總經理最終評核 Final President',
                span: 12,
                titleWidth: 120,
                // itemRender: { name: '$input', props: { placeholder: '請輸入文字(E,D,G,I,C)', readonly: currentLevel != 0 && currentLevel == 4 ? false : true,required:currentLevel != 0 && currentLevel == 4 ? false : true } }
                itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 4 ? false : true, disabled: currentLevel != 0 && currentLevel == 4 ? false : true, required:currentLevel != 0 && currentLevel == 4 ? false : true }, options: [
                    { value: "E-", label: "E-" },
                    { value: "E", label: "E" },
                    { value: "E+", label: "E+" },
                    { value: "D-", label: "D-" },
                    { value: "D", label: "D" },
                    { value: "D+", label: "D+" },
                    { value: "G-", label: "G-" },
                    { value: "G", label: "G" },
                    { value: "G+", label: "G+" },
                    { value: "I-", label: "I-" },
                    { value: "I", label: "I" },
                    { value: "I+", label: "I+" },
                    { value: "C-", label: "C-" },
                    { value: "C", label: "C" },
                    { value: "C+", label: "C+" }
                ]}
            },
            // {
            //     field: 'XmlData.Overall_PresidentValue',
            //     title: '總經理最終評核分數 Final President',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入額外評分', readonly: currentLevel != 0 && currentLevel == 4 ? false : true,required:currentLevel != 0 && currentLevel == 4 ? false : true } }
            // },
            {
                span: 24,
                slots: { default: 'column-PerformanceEvaluationPartA' }
            },
            {
                span: 24,
                slots: { default: 'column-Next' }
            },
            {
                field: 'XmlData.LastNext_Objectives',
                title: `！員工自訂工作目標/計劃(去年) 
                        Employee's Objectives(Last Year)`,
                span: 24,
                className: 'font-black',
                titleWidth: 250,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: true } }
            },
            {
                field: 'XmlData.Next_Objectives',
                title: `！員工自訂工作目標/計劃
                        Employee's Objectives`,
                span: 24,
                className: 'font-black',
                titleWidth: 250,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.LastNext_Measurement',
                title: `！員工自訂評量標準/工具(去年)  
                        Measurement(Last Year)`,
                span: 24,
                className: 'font-black',
                titleWidth: 250,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: true} }
            },            
            {
                field: 'XmlData.Next_Measurement',
                title: `！員工自訂評量標準/工具 
                        Measurement`,
                span: 24,
                className: 'font-black',
                titleWidth: 250,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'fedirections05' }
            },
            {
                field: 'XmlData.Next_Provide',
                title: ` `,
                span: 24,
                className: 'font-black',
                titleWidth: 360,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.LastNext_UnitManager',
                title: '單位主管意見(去年)',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: true} }
            },
            {
                field: 'XmlData.Next_UnitManager',
                title: '單位主管意見',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: currentLevel != 0 && currentLevel == 1 ? false : true, required:currentLevel != 0 && currentLevel == 1 ? false : true, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.LastNext_Manager',
                title: '部門主管意見(去年)',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: true} }
            },
            {
                field: 'XmlData.Next_Manager',
                title: '部門主管意見',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 8 }, readonly: currentLevel != 0 && currentLevel == 2 ? false : true, required:currentLevel != 0 && currentLevel == 2 ? false : true, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.SelfEvaluation_Quality': [{ required: true }],
            'XmlData.SelfEvaluation_Interity': [{ required: true }],
            'XmlData.SelfEvaluation_Professionalism': [{ required: true }],
            'XmlData.SelfEvaluation_Progress': [{ required: true }],
            'XmlData.SelfEvaluation_Problem': [{ required: true }],
            'XmlData.SelfEvaluation_Teamwork': [{ required: true }],
            'XmlData.SelfEvaluation_Spontaneity': [{ required: true }],
            'XmlData.Overall_Employee': [{ required: true }],
            'XmlData.Overall_Manager': [{ required: currentLevel != 0 && currentLevel == 2 ? true  : false }],
            'XmlData.Overall_VicePresident': [{ required: currentLevel != 0 && currentLevel == 3 ? true  : false }],
            'XmlData.Overall_President': [{ required: currentLevel != 0 && currentLevel == 4 ? true  : false }],
            'XmlData.Annual': [{ required: true }],
            'XmlData.Signature_Employee': [{ required: true }],
            'XmlData.Next_Objectives': [{ required: true }],
            'XmlData.Next_Measurement': [{ required: true }],
            'XmlData.Next_Provide': [{ required: true }],
          }
    }
    return forms;
}

// 35 差旅與交際費用申請單
function getTravelEntertainmentReport(readonly: boolean) {
    var forms: VxeFormProps = {        
        items: [
            {
                field: 'XmlData.Number',
                title: '出差編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '申請人姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Location',
                title: '地點',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Currency',
                title: '幣別',
                span: 12,
                itemRender: { name: '$select', props: { type: 'number', readonly: readonly }, options: [
                    { value: 0, label: "台幣" },
                    { value: 1, label: "美金" },
                    { value: 2, label: "日幣" },
                    { value: 3, label: "人民幣" },
                    { value: 4, label: "歐元" }
                ]}
            },
            {
                field: 'XmlData.Purpose',
                title: '出差目的',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Mileage',
                title: '私車公用每公里補助',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Code',
                title: '交通費代號',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: 'A', label: 'A.飛機 Airline' },
                    { value: 'B', label: 'B.公車/火車 Bus/Railway' },
                    { value: 'C', label: 'C.計程車 Taxi' },
                    { value: 'D', label: 'D.停車/過橋費 Parking/Toll Fee' },
                    { value: 'E', label: 'E.公務車 Company Car' },
                ]}
            },
            {
                title: '差旅費項目',
                span: 24,
                slots: { default: 'column-TravelItems' }
            },
            {
                title: '交際費項目',
                span: 24,
                slots: { default: 'column-EntertainmentItems' }
            },
            {
                field: 'XmlData.TotalAmount',
                title: '支出總金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.PrepaidAmount',
                title: '預付現金',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.BalanceDueToEmployee',
                title: '應付員工餘額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.BalanceDueFromEmployee',
                title: '員工應返還金額',
                span: 12,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },            
            {
                field: 'XmlData.EmployeeSignature',
                title: '申請人簽名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } }
            },
            {
                field: 'XmlData.ManagerApproval',
                title: '主管審核',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DivisionApproval',
                title: '部門授權',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 36 人員增補申請單
function getPersonnelSupplementary(readonly: boolean) {
    console.log('getPersonnelSupplementary.readonly:', readonly)
    var forms: VxeFormProps = {        
        items: [
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },            
            {
                field: 'XmlData.DepartmentId',
                title: '申請部門',
                span: 12,
                slots: { default: "column-department" }
            },
            // {
            //     field: 'XmlData.Name',
            //     title: '申請人',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.Position',
                title: '增補職稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Count',
                title: '增補人數',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Reason',
                title: '申請增補理由',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '擴大編制', label: '擴大編制' },
                    { value: '儲備人力', label: '儲備人力' },
                    { value: '辭職補充', label: '辭職補充' },
                ]}
            },
            {
                field: 'XmlData.OnBoardDate',
                title: '希望報到日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Gender',
                title: '性別',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '男', label: '男' },
                    { value: '女', label: '女' },
                    { value: '不拘', label: '不拘' },
                ]}
            },
            {
                field: 'XmlData.Marriage',
                title: '婚姻',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '已婚', label: '已婚' },
                    { value: '未婚', label: '未婚' },
                    { value: '不拘', label: '不拘' },
                ]}
            },
            {
                field: 'XmlData.Age',
                title: '年齡',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Education',
                title: '學歷',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '小學', label: '小學' },
                    { value: '中學', label: '中學' },
                    { value: '高中', label: '高中' },
                    { value: '專科', label: '專科' },
                    { value: '大學以上', label: '大學以上' },
                ]}
            },
            {
                field: 'XmlData.Department',
                title: '科系',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ForeignLanguage',
                title: '外文',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.English',
                title: '英文',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '稍可', label: '稍可' },
                    { value: '普通', label: '普通' },
                    { value: '很好', label: '很好' },
                ]}
            },
            {
                field: 'XmlData.Japanese',
                title: '日文',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '稍可', label: '稍可' },
                    { value: '普通', label: '普通' },
                    { value: '很好', label: '很好' },
                ]}
            },
            {
                field: 'XmlData.Experience',
                title: '經歷',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Personality',
                title: '個性',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Skill',
                title: '技能',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.JobDescription',
                title: '增加人員工作內容',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.PersonnelOpinion',
                title: '人事意見',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            // {
            //     field: 'XmlData.Manager',
            //     title: '單位主管',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.VicePresident',
            //     title: '副總經理',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.President',
            //     title: '總經理',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.DepartmentId': [{ required: true }],
            'XmlData.Name': [{ required: true }],
            'XmlData.OnBoardDate': [{ required: true }],
            'XmlData.Position': [{ required: true }],
            'XmlData.Count': [{ required: true }],
            'XmlData.Age': [{ required: true }],
            'XmlData.Department': [{ required: true }],
            'XmlData.ForeignLanguage': [{ required: true }],
            'XmlData.Experience': [{ required: true }],
            'XmlData.Personality': [{ required: true }],
            'XmlData.Skill': [{ required: true }],
            'XmlData.JobDescription': [{ required: true }],
            // 'XmlData.English': [{ required: true }],
            // 'XmlData.Japanese': [{ required: true }],
          }
    }
    console.log('getPersonnelSupplementary return:')
    return forms;
}

// 37 人事及薪資異動單
function getPersonnelandPayrollChangeRequest(readonly: boolean) {
    var forms: VxeFormProps = {        
        items: [
            // {
            //     field: 'XmlData.ApplicationDate',
            //     title: '申請日期',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            // },
            // {
            //     field: 'XmlData.Filler',
            //     title: '申請人',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.Type',
                title: '異動種類',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    // { value: '任用', label: '任用' },
                    { value: '升職', label: '升職' },
                    { value: '降職', label: '降職' },
                    { value: '調職', label: '調職' },
                    { value: '兼任', label: '兼任' },
                    // { value: '免職', label: '免職' },
                    // { value: '復職', label: '復職' },
                    { value: '調薪', label: '調薪' },
                    { value: '資遣', label: '資遣' },
                    { value: '留職停薪', label: '留職停薪' },
                ]}
            },
            {
                field: 'XmlData.EffectiveDate',
                title: '異動生效日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '原任職稱',
                span: 24,
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Position',
                title: '職稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OnBoardDate',
                title: '到職日',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '新任職稱',
                span: 24,
            },
            {
                field: 'XmlData.NewDepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-newdepartment" }
            },
            {
                field: 'XmlData.NewPosition',
                title: '職稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.NewOnBoardDate',
                title: '生效日',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '原薪',
                span: 24,
            },
            {
                field: 'XmlData.Grade',
                title: '職等',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Rank',
                title: '職級',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Salary',
                title: '本薪',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Allowance',
                title: '津貼',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.JobAllowance',
                title: '職務加給',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.SkillAllowance',
                title: '技術加給',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Total',
                title: '合計',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                title: '調整薪',
                span: 24,
            },
            {
                field: 'XmlData.AdjustGrade',
                title: '職等',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AdjustRank',
                title: '職級',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AdjustSalary',
                title: '本薪',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.AdjustAllowance',
                title: '津貼',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.AdjustJobAllowance',
                title: '職務加給',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.AdjustSkillAllowance',
                title: '技術加給',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.AdjustTotal',
                title: '合計',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Description',
                title: '申請說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.ManagerOpinion',
                title: '主管意見',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.Filler': [{ required: true }],
            'XmlData.Type': [{ required: true }],
            'XmlData.EffectiveDate': [{ required: true }],
            'XmlData.DepartmentId': [{ required: true }],
            'XmlData.Position': [{ required: true }],
            'XmlData.OnBoardDate': [{ required: true }],
            'XmlData.NewDepartmentId': [{ required: true }],
            'XmlData.NewOnBoardDate': [{ required: true }],
            'XmlData.Grade': [{ required: true }],
            'XmlData.Rank': [{ required: true }],
            'XmlData.Salary': [{ required: true }],
            'XmlData.Allowance': [{ required: true }],
            'XmlData.JobAllowance': [{ required: true }],
            'XmlData.SkillAllowance': [{ required: true }],
            'XmlData.Total': [{ required: true }],
            'XmlData.AdjustGrade': [{ required: true }],
            'XmlData.AdjustRank': [{ required: true }],
            'XmlData.AdjustSalary': [{ required: true }],
            'XmlData.AdjustAllowance': [{ required: true }],
            'XmlData.AdjustJobAllowance': [{ required: true }],
            'XmlData.AdjustSkillAllowance': [{ required: true }],
            'XmlData.AdjustTotal': [{ required: true }],
            'XmlData.Description': [{ required: true }],
            'XmlData.NewPosition': [{ required: true }],
          }
    }
    return forms;
}

// 38 離職申請與移交確認紀錄
function getResignationandHandoverecord(readonly: boolean, currentLevel: number) {
    var forms: VxeFormProps = {        
        items: [
            // {
            //     field: 'XmlData.Name',
            //     title: '姓名',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.HandoverName',
                title: '主管指定移交接收人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Position',
                title: '職位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.JobPosition',
                title: '擔任工作',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OnBoardDate',
                title: '到職日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ResignationDate',
                title: '擬離職日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '離職原因',
                span: 24,
                field: 'XmlData.LeavingReason',
                slots: { default: 'column-LeavingReasonType' }
            },
            {
                field: 'XmlData.Handover',
                title: '應移交事項',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Description',
                title: '說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '離職手續',
                span: 24,
            },
            {
                title: '工作單位',
                span: 24,
            },
            {
                field: 'XmlData.UnitDescription',
                title: '說明',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                field: 'XmlData.UnitRecipient',
                title: '接收人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                field: 'XmlData.UnitManager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                title: '管理部',
                span: 24,
            },
            {
                title: '資訊',
                span: 24,
            },
            {
                field: 'XmlData.ITDescription',
                title: '說明',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 2 ? false : true } }
            },
            {
                field: 'XmlData.ITRecipient',
                title: '接收人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 2 ? false : true } }
            },
            {
                field: 'XmlData.ITManager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 2 ? false : true } }
            },
            {
                title: '財務',
                span: 24,
            },
            {
                field: 'XmlData.FinanceDescription',
                title: '說明',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 3 ? false : true } }
            },
            {
                field: 'XmlData.FinanceRecipient',
                title: '接收人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 3 ? false : true } }
            },
            {
                field: 'XmlData.FinanceManager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 3 ? false : true } }
            },
            {
                title: '人事',
                span: 24,
            },
            {
                field: 'XmlData.HRDescription',
                title: '說明',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 4 ? false : true } }
            },
            {
                field: 'XmlData.HRRecipient',
                title: '接收人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 4 ? false : true } }
            },
            {
                field: 'XmlData.HRManager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 4 ? false : true } }
            },
            {
                field: 'XmlData.FinalResignationDate',
                title: '實際離職日期',
                span: 12,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: currentLevel != 0 && currentLevel == 4 ? false : true, placeholder: '請輸入日期',required:currentLevel != 0 && currentLevel == 4 ? false : true } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.Name': [{ required: true }],
            'XmlData.DepartmentId': [{ required: true }],
            'XmlData.JobPosition': [{ required: true }],
            'XmlData.Position': [{ required: true }],
            'XmlData.OnBoardDate': [{ required: true }],
            'XmlData.ResignationDate': [{ required: true }],
            'XmlData.LeavingReason': [{ required: true }],
            // 'XmlData.FinalResignationDate': [{ required:  currentLevel != 0 && currentLevel == 4 ? false : true }],
          }
    }
    return forms;
}

// 39 員工試用期滿考核表
function getEmployeeProbationAssessment(readonly: boolean, currentLevel: number, currentPosition: number, isUnitManager: boolean, isDepManager: boolean) {
    var forms: VxeFormProps = {        
        items: [
            {
                span: 24,
                slots: { default: 'directions01-39' }
            },
            {
                field: 'XmlData.ReviewDate',
                title: '考核日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '員工姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Position',
                title: '職稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmploymentDate',
                title: '到職日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.StartReviewDate',
                title: '考核日期(起)',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.EndReviewDate',
                title: '考核日期(訖)',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'directions02-39' }
            },
            {
                field: 'XmlData.Efficiency',
                title: '工作效率評分',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 15, placeholder: '請輸入分數(0~15)', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                span: 24,
                slots: { default: 'directions03-39' }
            },
            {
                field: 'XmlData.Quality',
                title: '工作品質評分',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 15, placeholder: '請輸入分數(0~15)', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                span: 24,
                slots: { default: 'directions04-39' }
            },
            {
                field: 'XmlData.Diligence',
                title: '勤勉負責評分',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 15, placeholder: '請輸入分數(0~15)', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                span: 24,
                slots: { default: 'directions05-39' }
            },
            {
                field: 'XmlData.Loyalty',
                title: '忠誠度評分',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 15, placeholder: '請輸入分數(0~15)', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                span: 24,
                slots: { default: 'directions06-39' }
            },
            {
                field: 'XmlData.ServiceAttitude',
                title: '服務精神評分',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 15, placeholder: '請輸入分數(0~20)', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                span: 24,
                slots: { default: 'directions07-39' }
            },
            {
                field: 'XmlData.Teamwork',
                title: '團隊精神評分',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 15, placeholder: '請輸入分數(0~20)', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                field: 'XmlData.PersonalLeave',
                title: '事假(H)',
                span: 6,
                itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', readonly: currentLevel == 0 ? false : true } }
            },
            {
                field: 'XmlData.SickLeave',
                title: '病假(H)',
                span: 6,
                itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', readonly: currentLevel == 0 ? false : true } }
            },
            {
                field: 'XmlData.Late',
                title: '遲到(分)',
                span: 6,
                itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', readonly: currentLevel == 0 ? false : true } }
            },
            {
                field: 'XmlData.AnnualLeave',
                title: '其他',
                span: 6,
                itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字', readonly: currentLevel == 0 ? false : true } }
            },
            // {
            //     span: 24,
            //     slots: { default: 'directions08-39' }
            // },
            {
                field: 'XmlData.Attendance',
                title: '出勤情況(0-10)',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', min:0, max: 10, placeholder: '請輸入分數(0~10)',  readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },             
            {
                field: 'XmlData.TotalScore',
                title: '總分',
                span: 24,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true  } }
            },  
            {
                field: 'XmlData.EvaluationResult',
                title: '考核結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true }, options: [
                    { value: '正式任用', label: '正式任用' },
                    { value: '不予任用', label: '不予任用' },
                    { value: '延長試用期', label: '延長試用期' },
                ]}
            },
            {
                field: 'XmlData.Month',
                title: '延長試用期(月)',
                span: 12,
                titleWidth: 110,
                itemRender: { name: '$input', props: { type: 'number', max: 3, min: 0, placeholder: '請輸入數字', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                field: 'XmlData.SupervisorComment',
                title: '單位主管評語',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字', readonly: currentLevel != 0 && currentLevel == 1 ? false : true, disabled: currentLevel != 0 && currentLevel == 1 ? false : true, required: currentLevel != 0 && currentLevel == 1 ? false : true } }
            },
            {
                field: 'XmlData.DepartmentManagerComment',
                title: '部門主管評語',
                span: 24,
                titleWidth: 110,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字', readonly: !isDepManager ? true : false, disabled: !isDepManager ? true : false, required: isDepManager } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.EvaluationResult': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.Efficiency': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.Quality': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.Diligence': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.Loyalty': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.ServiceAttitude': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.Teamwork': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.SupervisorComment': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.PersonalLeave': [{ required: true }],
            'XmlData.SickLeave': [{ required: true }],
            'XmlData.Late': [{ required: true }],
            'XmlData.AnnualLeave': [{ required: true }],
            'XmlData.Attendance': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.Month': [{ required: currentLevel != 0 && currentLevel == 1 ? true  : false }],
            'XmlData.DepartmentManagerComment': [{ required: isUnitManager}],
            //'XmlData.Attendance': [{ required: true }],
           // 'XmlData.Late': [{ required: true }],
            //'XmlData.Overall_Manager': [{ required: currentLevel != 0 && currentLevel == 2 ? true  : false }],
          }
    }
    return forms;
}

// 40 物性檢測申請表
function getPhysicalPropertiesTesting(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.ApplicationDate',
                title: '填寫日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.RequireDate',
                title: '需求日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '申請人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Number',
                title: '編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } }
            },
            {
                field: 'XmlData.Type',
                title: '檢測事由',
                span: 12,
                itemRender: { name: "$select", props: {readonly: readonly, disabled: readonly}, options: model ? Object.values(model.enums.TestingType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
                field: 'XmlData.OtherType',
                title: '檢測事由-其他',
                titleWidth: 120,
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ItemIndex',
                title: '項次',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductName',
                title: '品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },            
            {
                title: '檢測項目',
                span: 12,
                slots: { default: "column-TestingItem" }
            },
            {
                field: 'XmlData.OtherItems',
                title: '檢測項目-其他',
                span: 12,
                titleWidth: 120,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Hour',
                title: '小時/天',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Temperature',
                title: '℃',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Quantity',
                title: '數量',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Returns',
                title: '是否退樣',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 41 檢驗紀錄表
function getTestingReport(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.TestingPerson',
                title: '檢驗人員',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductId',
                title: '光益產品',
                span: 12,
                slots: { default: 'column-Product' }
            },  
            {
                field: 'XmlData.Product',
                title: '產品名稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReportNo',
                title: '報告編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },      
            {
                field: 'XmlData.Specifications',
                title: '產品規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TestingDate',
                title: '檢測日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ItemNo',
                title: 'Nitoms品番',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.JobTickeNo',
                title: '工單號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },  
            {
                field: 'XmlData.Subject',
                title: '實驗目的',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },  
            {
                field: 'XmlData.LotNo',
                title: '產品批號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Sources',
                title: '試樣來源',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Quantity',
                title: '產品數量',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入數量', readonly: readonly } }
            },      
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.RemarkIsRed',
                title: '備註標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '外觀',
                span: 24
            },
            {
                field: 'XmlData.AppearanceTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.AppearanceStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AppearanceActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AppearanceResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },            
            {
                field: 'XmlData.AppearanceDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AppearanceIsRed',
                title: '外觀標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '顏色',
                span: 24
            },
            {
                field: 'XmlData.ColorTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.ColorStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ColorActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ColorResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.ColorDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ColorIsRed',
                title: '顏色標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '總厚度',
                span: 24
            },
            {
                field: 'XmlData.ThicknessTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.ThicknessStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ThicknessActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ThicknessResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.ThicknessDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ThicknessIsRed',
                title: '總厚度標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '初期黏著力',
                span: 24
            },
            {
                field: 'XmlData.TackTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.TackStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TackActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TackResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.TackDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TackIsRed',
                title: '初期黏著力標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '鋼板黏著力',
                span: 24
            },
            {
                field: 'XmlData.PeelTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.PeelStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PeelActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PeelResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.PeelDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PeelIsRed',
                title: '鋼板黏著力標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '保持力',
                span: 24
            },
            {
                field: 'XmlData.HoldingTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.HoldingStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.HoldingActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.HoldingResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.HoldingDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.HoldingIsRed',
                title: '保持力標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '抗拉強度',
                span: 24
            },
            {
                field: 'XmlData.TensileTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.TensileStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TensileActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TensileResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.TensileDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.TensileIsRed',
                title: '抗拉強度標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '延伸率',
                span: 24
            },
            {
                field: 'XmlData.ElongationTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.ElongationStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ElongationActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ElongationResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.ElongationDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ElongationIsRed',
                title: '延伸率標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                title: '卸卷力',
                span: 24
            },
            {
                field: 'XmlData.UnwindTesting',
                title: '檢測方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: "目視(Visual Estimation)", label: "目視(Visual Estimation)" },
                    { value: "IMP Testing Method", label: "IMP Testing Method" },
                    { value: "ASTM D1000", label: "ASTM D1000" },
                    { value: "ASTM D3330", label: "ASTM D3330" },
                    { value: "ASTM D3652", label: "ASTM D3652"  },
                    { value: "ASTM D3654", label: "ASTM D3654"  },
                    { value: "ASTM D3759", label: "ASTM D3759"  },
                    { value: "ASTMD 3811", label: "ASTMD 3811"  },
                    { value: "PSTC-133", label: "PSTC-133"  },
                    { value: "PSTC-6", label: "PSTC-6"  },
                    { value: "PSTC-107", label: "PSTC-107"  },
                    { value: "PSTC-8", label: "PSTC-8"  },
					{ value: "PSTC-101", label: "PSTC-101"  },
					{ value: "PSTC-131", label: "PSTC-131"  },
                    { value: "J.DOW", label: "J.DOW"  }
                ]}
            },
            {
                field: 'XmlData.UnwindStandard',
                title: '標準值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.UnwindActual',
                title: '檢測值',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.UnwindResult',
                title: '測試結果',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '合格', label: '合格' },
                    { value: '不合格', label: '不合格' },
                ]}
            },
            {
                field: 'XmlData.UnwindDescription',
                title: '說明',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.UnwindIsRed',
                title: '卸卷力標紅',
                span: 24,
                itemRender: { name: '$checkbox', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 42 出差編號填寫表
function getBusinessTripNumberList(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Date',
                title: '出差日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.BusinessTripNumber',
                title: '出差編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '人員姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Days',
                title: '出差天數',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Localtion',
                title: '目的地',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Description',
                title: '出差目的',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 43 製作樣品通知單
function getSampleNotificationForm(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Number',
                title: '樣品單編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } }
            },
            {
                field: 'XmlData.ApplicationDate',
                title: '開單日',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.RequirementDate',
                title: '需求日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '需求單位',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    // { value: '任用', label: '任用' },
                    { value: '外銷', label: '外銷' },
                    { value: '內銷', label: '內銷' },
                ]}
            },
            // {
            //     field: 'XmlData.ProductId',
            //     title: '產品',
            //     span: 12,
            //     slots: { default: 'column-Product' }
            // },    
            {
                field: 'XmlData.Sales',
                title: '負責業務',
                span: 12,
                slots: { default: "column-employeeId" }
            },
            {
                field: 'XmlData.PickupType',
                title: '取件方式',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    // { value: '任用', label: '任用' },
                    { value: 0, label: '業務自取' },
                    { value: 1, label: '工廠寄出' },
                    { value: 2, label: '其他' },
                ]}
            },
            {
                field: 'XmlData.CustomerNumber',
                title: '客戶代號',
                span: 12,
                titleWidth: 130,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.CustomerName',
                title: '客戶名稱',
                span: 12,
                titleWidth: 130,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ContactPersons',
                title: '聯絡人',
                span: 12,
                titleWidth: 130,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Phone',
                title: '電話',
                span: 12,
                titleWidth: 130,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Address',
                title: '地址',
                span: 24,
                titleWidth: 130,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },          
            // {
            //     field: 'XmlData.Remark',
            //     title: '備註',
            //     span: 24,
            //     itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            // },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-SampleNotificationFormItems' }
            },
            // {
            //     field: 'XmlData.Count',
            //     title: '數量加總',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                title: '樣品寄送資料',
                span: 24,
                slots: { default: 'column-StaffItems' }
            },
            // {
            //     field: 'XmlData.SendDate',
            //     title: '寄送日期',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            // },
            // {
            //     field: 'XmlData.Company',
            //     title: '快遞公司',
            //     span: 12,
            //     titleWidth: 130,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // }, 
            // {
            //     field: 'XmlData.TrackingNumber',
            //     title: '快遞追蹤單號',
            //     span: 12,
            //     titleWidth: 130,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },            
            {
                field: 'XmlData.Description',
                title: '特別說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.DepartmentId':[{ required: true }],
            'XmlData.CustomerName':[{ required: true }],
          }
    }
    return forms;
}

// 44 銷貨折讓原因單
function getSalesAllowance(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.CustomerId',
                title: '客戶',
                span: 12,
                slots: { default: "column-Customer" }
            },
            {
                field: 'XmlData.InvoiceId',
                title: '發票',
                span: 12,
                slots: { default: "column-Invoice" }
            },
            {
                field: 'XmlData.Amount',
                title: '折讓金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Month',
                title: '收款月份',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', max: 12, placeholder: '請輸入文字',readonly: readonly } }
            },
            {
                field: 'XmlData.Description',
                title: '原因說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Amount': [{ required: true }],
          }
    }
    return forms;
}

//生產規格首件QC檢驗紀錄表(裁切) 45
function getTypeBProductionCheckB(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Manager',
                title: '主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            // {
            //     title: '複卷資料',
            //     span: 24,
            //     slots: { default: 'column-WinderItems' }
            // },
            {
                title: '切卷資料',
                span: 24,
                slots: { default: 'column-RollItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Manager': [{ required: true }],
          }
    }
    return forms;
}

//來料檢驗 46
function getIQCTable(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.CategoryId',
                title: '類別',
                span: 12,
                slots: { default: "column-category" }
            },
            {
                field: 'XmlData.Supplier',
                title: '供應商',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Purchase',
                title: '進貨單',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            // {
            //     field: 'XmlData.TestName',
            //     title: '檢驗人員',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },            
            {
                field: 'XmlData.TestTime',
                title: '檢驗日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '類別資料',
                span: 24,
                slots: { default: 'column-CategoryItem' }
            },
            {
                title: '類別資料',
                span: 24,
                slots: { default: 'column-Items' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
        ],
        rules: {
          'XmlData.CategoryId': [{ required: true }],
        }
    }
    return forms;
}

//品質異常處理單 47
function getQualityHandlingForm(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.ApplicationDate',
                title: '開單日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.Number',
                title: '異常單編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } }
            },
            {
                span: 12,
            },
            {
                field: 'XmlData.ErrorSteps',
                title: '異常發生階段',
                span: 12,
                titleWidth: 120,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: 'A', label: '供應商造成' },
                    { value: 'B', label: '廠內生產過程' },
                    { value: 'C', label: '貨運過程' },
                    { value: 'D', label: '其它' }
                ]}
                //slots: { default: 'column-ErrorSteps' }
            },
            {
                field: 'XmlData.ErrorStepsText',
                title: '其他',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DiscoveryUnits',
                title: '發現單位',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    { value: '1', label: '分條' },
                    { value: '2', label: '覆卷' },
                    { value: '3', label: '裁切' },
                    { value: '4', label: '包裝' },
                    { value: '5', label: '其它' }
                ]}
                //slots: { default: 'column-DiscoveryUnits' }
            },
            {
                field: 'XmlData.DiscoveryUnitsText',
                title: '其他',
                span: 8,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DiscoveryTime',
                title: '發現時間',
                span: 8,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '處理方式',
                span: 18,
                slots: { default: 'column-Handling' }
            },
            {
                field: 'XmlData.HandlingText',
                title: '其他',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.IncomingQuantity',
                title: '不良數/進貨總數',
                span: 12,
                titleWidth: 120,
                itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Vendor',
                title: '廠商',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Customer',
                title: '客戶',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PurchaseNumber',
                title: '採購單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.JobTicketNo',
                title: '工單單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductName',
                title: '料號/品名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProductionUnit',
                title: '製造單位',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                span: 24,
                title: '異常現象',
            },
            {
                field: 'XmlData.Anomaly',
                title: '異常現象',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.AnomalyEmployee',
                title: '填表者',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.AnomalyManager',
                title: '單位主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ResponsibilityUnit',
                title: '判定品質異常責任單位',
                titleWidth: 180,
                span: 18,
                slots: { default: 'column-ResponsibilityUnit' }
            },
            {
                field: 'XmlData.ResponsibilityUnitText',
                title: '其他',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                span: 24,
                title: '異常分析',
            },
            {
                field: 'XmlData.Reason',
                title: '異常原因',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.ReasonEmployee',
                title: '填表者',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReasonManager',
                title: '單位主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                span: 24,
                titleWidth: 150,
                title: '處理及預防再發生',
            },
            {
                title: '分類',
                span: 18,
                slots: { default: 'column-RemedialType' }
            },
            {
                field: 'XmlData.RemedialTypeText',
                title: '其他',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Remedial',
                title: '應急處理對策',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.RemedialEmployee',
                title: '填表者',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.RemedialManager',
                title: '單位主管',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Prevention',
                title: '再發防止對策',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.EstimatedDate',
                title: '預計完成日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.ActualDate',
                title: '實際完成日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.PreventionCheckName',
                title: '確認者',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                span: 24,
                title: '索賠方式',
            },
            {
                field: 'XmlData.Damages',
                title: '索賠方式',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.DamagesDate',
                title: '賠償日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.DamagesCheckName',
                title: '確認者',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                field: 'XmlData.ManagerContent',
                title: '主管批示',
                span: 12,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.PresidentContent',
                title: '總經理批示',
                span: 12,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-imgfile' }
            },
        ],
        rules: {
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.ErrorSteps': [{ required: true }],
        }
    }
    return forms;
}

//品質異常原因調查表 48
function getQualityAbnormalityCauseForm(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.DocumentNumber',
                title: '文件編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } }
            },
            {
                field: 'XmlData.CustomerId',
                title: '廠商',
                span: 12,
                slots: { default: 'column-Customer' }
            },
            // {
            //     field: 'XmlData.VendorNumber',
            //     title: '廠商編號',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.ProductId',
                title: '產品',
                span: 12,
                slots: { default: 'column-Product' }
            },            
            // {
            //     field: 'XmlData.ProductColor',
            //     title: '品名顏色',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.ProductNumber',
            //     title: '品號',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.PurchaseNumber',
                title: '採購單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OrderNumber',
                title: '製令單號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Specifications',
                title: '規格',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.Amount',
                title: '金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', readonly: readonly, placeholder: '請輸入數字' } }
            },
            {
                field: 'XmlData.Quantity',
                title: '異常數量',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '分類',
                span: 18,
                slots: { default: 'column-ProblemContentType' }
            },
            {
                field: 'XmlData.ProblemContentTypeText',
                title: '其他',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProblemContent',
                title: '問題現象',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.Machine',
                title: '機台',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProblemDone',
                title: '填表',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.CheckManager',
                title: '主管確認',
                span: 6,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ProblemDate',
                title: '日期',
                span: 6,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                field: 'XmlData.Prevention',
                title: '矯正與預防措施',
                titleWidth: 120,
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.PreventionDone',
                title: '填表',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.PreventionDate',
                title: '日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'column-imgfile' }
            },
          ],
          rules: {
            'XmlData.CustomerId': [{ required: true }],
          }
    }
    return forms;
}

// 信息聯絡處理單 49
function getInformationContactForm(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                title: '聯絡部門',
                span: 24,
                slots: { default: 'column-ContactDepartment' }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                title: '信息摘要類別',
                span: 24,
                slots: { default: 'column-MessageType' }
            },
            {
                field: 'XmlData.Message',
                title: '信息摘要',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.MessageEmployee',
                title: '需求人員',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MessageDate',
                title: '回覆時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                field: 'XmlData.UnitA',
                title: '會辦單位',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.ReceiptDateA',
                title: '受文時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.UnitEmployeeA',
                title: '需求人員',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.UnitDateA',
                title: '回覆時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                field: 'XmlData.UnitB',
                title: '會辦單位',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.ReceiptDateB',
                title: '受文時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.UnitEmployeeB',
                title: '需求人員',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.UnitDateB',
                title: '回覆時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'column-hr' }
            },
            {
                field: 'XmlData.ManagerContent',
                title: '主管處理結果及意見回覆',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.ManagerReply',
                title: '主管回覆',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.ReplyDate',
                title: '回覆時間',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 50 出差工作日報表
function getBusinessTripDailyReport(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.Number',
                title: '出差編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.EmployeeName',
                title: '出差人',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Remark',
                title: '備註',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                title: '項目資料',
                span: 24,
                slots: { default: 'column-BusinessTripDailyReportItems' }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
          }
    }
    return forms;
}

// 51 銷貨折讓原因單(內銷)
function getDomesticSalesAllowance(readonly: boolean) {
    var forms: VxeFormProps = {
        items: [
            {
                field: 'XmlData.ApplicationDate',
                title: '申請日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.CustomerId',
                title: '客戶',
                span: 12,
                slots: { default: "column-Customer" }
            },
            // {
            //     field: 'XmlData.InvoiceId',
            //     title: '發票',
            //     span: 12,
            //     slots: { default: "column-Invoice" }
            // },
            {
                field: 'XmlData.Amount',
                title: '折讓金額',
                span: 12,
                itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', readonly: readonly } }
            },
            {
                field: 'XmlData.Month',
                title: '收款月份',
                span: 12,
                itemRender: { name: '$input', props: { type: 'number', max: 12, placeholder: '請輸入文字',readonly: readonly } }
            },
            {
                field: 'XmlData.Description',
                title: '原因說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.Amount': [{ required: true }],
          }
    }
    return forms;
}

// 52 人事異動單
function getPersonnelChangeRequest(readonly: boolean) {
    var forms: VxeFormProps = {        
        items: [
            // {
            //     field: 'XmlData.ApplicationDate',
            //     title: '申請日期',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            // },
            // {
            //     field: 'XmlData.Filler',
            //     title: '申請人',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            {
                field: 'XmlData.Type',
                title: '異動種類',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    // { value: '任用', label: '任用' },
                    { value: '升職', label: '升職' },
                    { value: '降職', label: '降職' },
                    { value: '調職', label: '調職' },
                    { value: '兼任', label: '兼任' },
                    // { value: '免職', label: '免職' },
                    // { value: '復職', label: '復職' },
                    { value: '調薪', label: '調薪' },
                    { value: '資遣', label: '資遣' },
                    { value: '留職停薪', label: '留職停薪' },
                ]}
            },
            {
                field: 'XmlData.EffectiveDate',
                title: '異動生效日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '原任職稱',
                span: 24,
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.Position',
                title: '職稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.OnBoardDate',
                title: '到職日',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                title: '新任職稱',
                span: 24,
            },
            {
                field: 'XmlData.NewDepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-newdepartment" }
            },
            {
                field: 'XmlData.NewPosition',
                title: '職稱',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.NewOnBoardDate',
                title: '生效日',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            // {
            //     title: '原薪',
            //     span: 24,
            // },
            // {
            //     field: 'XmlData.Grade',
            //     title: '職等',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Rank',
            //     title: '職級',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Salary',
            //     title: '本薪',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Allowance',
            //     title: '津貼',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.JobAllowance',
            //     title: '職務加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.SkillAllowance',
            //     title: '技術加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Total',
            //     title: '合計',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     title: '調整薪',
            //     span: 24,
            // },
            // {
            //     field: 'XmlData.AdjustGrade',
            //     title: '職等',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustRank',
            //     title: '職級',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustSalary',
            //     title: '本薪',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustAllowance',
            //     title: '津貼',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustJobAllowance',
            //     title: '職務加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustSkillAllowance',
            //     title: '技術加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustTotal',
            //     title: '合計',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            {
                field: 'XmlData.Description',
                title: '申請說明',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                field: 'XmlData.ManagerOpinion',
                title: '主管意見',
                span: 24,
                itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            },
            {
                span: 24,
                slots: { default: 'column-file' }
            },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.Filler': [{ required: true }],
            'XmlData.Type': [{ required: true }],
            'XmlData.EffectiveDate': [{ required: true }],
            'XmlData.DepartmentId': [{ required: true }],
            'XmlData.Position': [{ required: true }],
            'XmlData.OnBoardDate': [{ required: true }],
            'XmlData.NewDepartmentId': [{ required: true }],
            'XmlData.NewOnBoardDate': [{ required: true }],
            'XmlData.Grade': [{ required: true }],
            'XmlData.Rank': [{ required: true }],
            'XmlData.Salary': [{ required: true }],
            'XmlData.Allowance': [{ required: true }],
            'XmlData.JobAllowance': [{ required: true }],
            'XmlData.SkillAllowance': [{ required: true }],
            'XmlData.Total': [{ required: true }],
            'XmlData.AdjustGrade': [{ required: true }],
            'XmlData.AdjustRank': [{ required: true }],
            'XmlData.AdjustSalary': [{ required: true }],
            'XmlData.AdjustAllowance': [{ required: true }],
            'XmlData.AdjustJobAllowance': [{ required: true }],
            'XmlData.AdjustSkillAllowance': [{ required: true }],
            'XmlData.AdjustTotal': [{ required: true }],
            'XmlData.Description': [{ required: true }],
            'XmlData.NewPosition': [{ required: true }],
          }
    }
    return forms;
}

// 53 員工異動單
function getEmployeeChangeRequest(readonly: boolean) {
    var forms: VxeFormProps = {        
        items: [
            {
                field: 'XmlData.Type',
                title: '任職狀態',
                span: 12,
                itemRender: { name: '$select', props: { readonly: readonly, disabled: readonly }, options: [
                    // { value: '任用', label: '任用' },
                    { value: '到職', label: '到職' },
                    { value: '離職', label: '離職' },
                    { value: '調任', label: '調任' },
                ]}
            },            
            {
                field: 'XmlData.ApplicationDate',
                title: '到職日期',
                span: 12,
                itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            },
            {
                field: 'XmlData.DepartmentId',
                title: '部門',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.EmployeeNo',
                title: '員工編號',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },            
            {
                field: 'XmlData.EmployeeName',
                title: '姓名',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                field: 'XmlData.MobilePhone',
                title: '手機號碼',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },            
            {
                field: 'XmlData.Mail',
                title: '電子郵件',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            },
            {
                title: '調任單位',
                span: 24,
            },
            {
                field: 'XmlData.UnitId',
                title: '原單位',
                span: 12,
                slots: { default: "column-department" }
            },
            {
                field: 'XmlData.NewUnitId',
                title: '新單位',
                span: 12,
                slots: { default: "column-newdepartment" }
            },
            {
                field: 'XmlData.Shift',
                title: '班別',
                span: 12,
                itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly} }
            },
			{
                field: 'XmlData.Manager',
                title: '單位主管',
                span: 12,
                slots: { default: "column-employeeId" }
            },
            // {
            //     field: 'XmlData.NewOnBoardDate',
            //     title: '到職日期',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', readonly: readonly, placeholder: '請輸入日期' } }
            // },
            // {
            //     title: '原薪',
            //     span: 24,
            // },
            // {
            //     field: 'XmlData.Grade',
            //     title: '職等',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Rank',
            //     title: '職級',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Salary',
            //     title: '本薪',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Allowance',
            //     title: '津貼',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.JobAllowance',
            //     title: '職務加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.SkillAllowance',
            //     title: '技術加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Total',
            //     title: '合計',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     title: '調整薪',
            //     span: 24,
            // },
            // {
            //     field: 'XmlData.AdjustGrade',
            //     title: '職等',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustRank',
            //     title: '職級',
            //     span: 12,
            //     itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustSalary',
            //     title: '本薪',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustAllowance',
            //     title: '津貼',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustJobAllowance',
            //     title: '職務加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustSkillAllowance',
            //     title: '技術加給',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.AdjustTotal',
            //     title: '合計',
            //     span: 12,
            //     itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: readonly } }
            // },
            // {
            //     field: 'XmlData.Description',
            //     title: '申請說明',
            //     span: 24,
            //     itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            // },
            // {
            //     field: 'XmlData.ManagerOpinion',
            //     title: '主管意見',
            //     span: 24,
            //     itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: readonly, placeholder: '請輸入文字' } }
            // },
            // {
            //     span: 24,
            //     slots: { default: 'column-file' }
            // },
          ],
          rules: {
            'XmlData.ApplicationDate': [{ required: true }],
            'XmlData.Filler': [{ required: true }],
            'XmlData.Type': [{ required: true }],
            'XmlData.EffectiveDate': [{ required: true }],
            'XmlData.DepartmentId': [{ required: true }],
            'XmlData.Position': [{ required: true }],
            'XmlData.OnBoardDate': [{ required: true }],
            'XmlData.NewDepartmentId': [{ required: true }],
            'XmlData.NewOnBoardDate': [{ required: true }],
            'XmlData.Grade': [{ required: true }],
            'XmlData.Rank': [{ required: true }],
            'XmlData.Salary': [{ required: true }],
            'XmlData.Allowance': [{ required: true }],
            'XmlData.JobAllowance': [{ required: true }],
            'XmlData.SkillAllowance': [{ required: true }],
            'XmlData.Total': [{ required: true }],
            'XmlData.AdjustGrade': [{ required: true }],
            'XmlData.AdjustRank': [{ required: true }],
            'XmlData.AdjustSalary': [{ required: true }],
            'XmlData.AdjustAllowance': [{ required: true }],
            'XmlData.AdjustJobAllowance': [{ required: true }],
            'XmlData.AdjustSkillAllowance': [{ required: true }],
            'XmlData.AdjustTotal': [{ required: true }],
            'XmlData.Description': [{ required: true }],
            'XmlData.NewPosition': [{ required: true }],
          }
    }
    return forms;
}


const forms = [
    { contentType: 0, type: 0 },
    { contentType: 1, type: 0 },
    { contentType: 2, type: 0 },
    { contentType: 3, type: 0 },
    { contentType: 4, type: 0 },
    { contentType: 5, type: 0 },
    { contentType: 6, type: 0 },
    { contentType: 7, type: 0 },
    { contentType: 8, type: 0 },
    { contentType: 9, type: 0 },
    { contentType: 10, type: 0 },
    { contentType: 11, type: 0 },
    { contentType: 12, type: 0 },
    { contentType: 13, type: 0 },
    { contentType: 14, type: 0 },
    { contentType: 15, type: 0 },
    { contentType: 16, type: 1 },
    { contentType: 17, type: 1 },
    { contentType: 18, type: 1 },
    { contentType: 19, type: 1 },
    { contentType: 20, type: 1 },
    { contentType: 21, type: 1 },
    { contentType: 22, type: 1 },
    { contentType: 23, type: 1 },
    { contentType: 24, type: 1 },
    { contentType: 25, type: 0 },
    { contentType: 26, type: 1 },
    { contentType: 27, type: 1 },
    { contentType: 28, type: 1 },
    { contentType: 29, type: 1 },
    { contentType: 30, type: 0 },
    { contentType: 31, type: 0 },
    { contentType: 32, type: 0 },
    { contentType: 33, type: 0 },
    { contentType: 34, type: 0 },
    { contentType: 35, type: 0 },
    { contentType: 36, type: 0 },
    { contentType: 37, type: 0 },
    { contentType: 38, type: 0 },
    { contentType: 39, type: 0 },
    { contentType: 40, type: 0 },
    { contentType: 41, type: 0 },
    { contentType: 42, type: 0 },
    { contentType: 43, type: 0 },
    { contentType: 44, type: 0 },
    { contentType: 45, type: 1 },
    { contentType: 46, type: 1 },
    { contentType: 47, type: 0 },
    { contentType: 48, type: 0 },
    { contentType: 49, type: 0 },
    { contentType: 50, type: 0 },    
    { contentType: 51, type: 0 },
    { contentType: 52, type: 0 },
]

export function getType(contentType: any) {
    const result = forms.find((e: any) => e.contentType == contentType);
    return result ? result.type : 0;
}